// lib
import { useState } from "react";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Stack, Grid, Box, Typography, Link } from '@mui/material';
import Logo from "components/Logo";
import LoginForm from "./LoginForm";
import { PWD_FIND_URL } from 'config'
// ----------------------------------------------------------------------
const LoginWrap = styled('div')(({ theme }) => {
  const isLight = theme.palette.mode === 'light';
  return {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '600px',
    height: '100%',
    padding: '26vw 40px 20PX',
    textAlign: 'center',
    '&:before': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      backgroundImage: 'url(/img/bg_login.png)',
      backgroundSize: '115%',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
      zIndex: '-1',
      opacity: isLight ? '1' : '.3'
    }
  };
 
});

const LoginTit = styled("img")(({ theme }) => ({
  width: "190px",
  height: "auto",
  marginTop: "14px",
}));

const PasswordBox = styled(Box)(({ theme }) => ({
  width: '100% ',
  height: '48px',
  display: ' flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.background.neutral,
  zIndex: 2
}));
// ----------------------------------------------------------------------
// 로그인 화면 
// ----------------------------------------------------------------------
export default function Login() {
  const theme = useTheme();
  
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} sx={{ height: '100%' }}>
        <Stack alignItems="center" py={3} sx={{ minHeight: 'calc(100% - 48px)' }}>
          <LoginWrap>
            <Logo sx={{ width: '190px', height: '37px' }} />
            {/* <LoginTit src="/img/img_login.png" alt="스마트캠퍼스" /> */}
            <LoginForm />
          </LoginWrap>
        </Stack>
        <PasswordBox>
          <Typography variant="body3" color={theme.palette.text.secondary} pr={1.8}>
            비밀번호를 잊어버리셨나요?
          </Typography>
          <Typography 
            component="a" 
            variant="body3" 
            sx={{ textDecoration: 'underline' }}
            href={PWD_FIND_URL}
            rel="noopener"
            target="_blank"
            >
            비밀번호 찾기
          </Typography>
        </PasswordBox>
      </Grid>
    </Grid>
  );
}
