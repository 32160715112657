import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from 'config';
import useLocales from 'hooks/useLocales';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'redux/store';
import { useCommMenu, CommMenuState } from 'redux/slices/commMenuState';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick'
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const verticalLayout = themeLayout === 'vertical';
  const { user } = useAuth();
  const { currentLang } = useLocales();
  // 메뉴 정보 조회
  const dispatch = useDispatch();
  const { searchMenu, changeLang } = useCommMenu();
  // 관리자의 경우 사용자 변경 시 메뉴 재조회 필요해서
  useEffect(() => {
    // console.log(`#### 메뉴 정보 조회 #### ${currentLang.value}`);
    user && dispatch(searchMenu(currentLang.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // console.log('### 언어 변경 ####');
    changeLang(currentLang.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  // 22.01.08 화면 닫기
  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 }
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick} sx={{ paddingTop: `${HEADER.MOBILE_HEIGHT}px` }}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
