// @mui
import { Box, BoxProps } from "@mui/material";
import Scrollbar from './../../components/Scrollbar';

// ----------------------------------------------------------------------
// 프로그램명 : 팝업메인
// ----------------------------------------------------------------------
interface PopupMainprops extends BoxProps {
  mtype?:
    | 'useBtn'
    | 'useNothead'
  showIcon?: boolean;
}

export default function PopupWrap({
  children,
  sx,
  onClick,
  mtype,
  ...other
}: PopupMainprops) {
  if(mtype==='useBtn'){
    return (
      <Scrollbar sx={{ height: "calc(100% - 52px)", paddingBottom: "60px" }}>
        <Box>{children}</Box>
      </Scrollbar>
    );
  }
  if(mtype==='useNothead'){
    return (
      <Scrollbar sx={{ height: '100%' }}>
        <Box>{children}</Box>
      </Scrollbar>
    );
  }

  return (
    <Scrollbar sx={{ height: "calc(100% - 52px)" }}>
      <Box>{children}</Box>
    </Scrollbar>
  );
}
