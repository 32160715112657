// @mui
import { Box, BoxProps, IconButton } from '@mui/material';
import Iconify from 'components/Iconify';
import { IconButtonAnimate } from 'components/animate';
import { styled, useTheme } from '@mui/material/styles';

type PopupWrapPros = BoxProps & {
  onClose?: VoidFunction;
};
// ----------------------------------------------------------------------
// 프로그램명 : 팝업전체
// ----------------------------------------------------------------------
export default function PopupWrap({
  children,
  sx,
  onClose,
  ...other
}: PopupWrapPros) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        ...sx
      }}
    >
      {children}
      {onClose && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '14px',
            right: '14px',
            width: '24px',
            height: '24px',
            padding: '0'
          }}
          onClick={(e) => {
            if (onClose) {
              onClose();
            }
          }}
        >
          <Iconify
            icon={'mdi:window-close'}
            width={24}
            height={24}
            color={theme.palette.text.primary}
          />
        </IconButton>
      )}
    </Box>
  );
}
