import { combineReducers, ReducersMapObject } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import httpStatusReducer from './slices/httpStatus';
import commMenuStatusReducer from './slices/commMenuState';
import alertDialogState from './slices/alertDialogState';
import commCurrentMenuState from './slices/commCurrentMenuState';
import commPushState from './slices/commPushState';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

// 기본
const defaultReducer = {
  HttpStatus: httpStatusReducer,
  CommMenuState: commMenuStatusReducer,
  AlertDialogState: alertDialogState,
  CommCurrentMenuState: commCurrentMenuState,
  CommPushState: commPushState
};

const rootReducer = combineReducers(defaultReducer);

function createReducer<M extends ReducersMapObject<any, any>>(asyncReducers: M) {
  return combineReducers({
    ...defaultReducer,
    ...asyncReducers
  });
}
export default createReducer;

export { rootPersistConfig, rootReducer };
