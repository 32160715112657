import { useTranslation } from 'react-i18next';
import { LANGS } from '_szcom/types/globaltypes';
import { setYupLocale } from 'locales/i18n';
// ----------------------------------------------------------------------
export default function useLocales() {
  const { i18n, t } = useTranslation();
  const lang = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === lang) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    if (i18n.isInitialized && i18n.language !== newlang) {
      // onChangeLang(newlang);
      i18n.changeLanguage(newlang);
      setYupLocale(null, t);
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    t: (name: string, comment?: string) => {
      const localName = t(name);
      if (localName === name) {
        return comment || name;
      }
      return localName;
    },
    currentLang,
    allLang: LANGS
  };
}
