import { createSlice } from '@reduxjs/toolkit';
import { store } from 'redux/store';
import { GDS_MENU } from '_szcom/types/globaltypes';
import { setCurrentMenu } from '_szcom/utils/global';

// 현재 선택 메뉴 정보 관리
const PGM_ID = 'CommCurrentMenuState';
export type CommCurrentMenuState = {
  selectedMenu: GDS_MENU | undefined;
};

const initialState: CommCurrentMenuState = {
  selectedMenu: undefined
};

const slice = createSlice({
  name: PGM_ID,
  initialState,
  reducers: {
    selectMenu(state, action) {
      state.selectedMenu = action.payload.ds;
    }
  }
});

// ----------------------------------------------------------------------
// 현재 처리 메뉴 설정
export const selectMenu = (menu: GDS_MENU | undefined) => async () => {
  const { dispatch } = store;
  // 서버 호출 필요 : 로그 남기기
  setCurrentMenu(menu);
  dispatch(slice.actions.selectMenu({ ds: menu }));
};

// Reducer
export default slice.reducer;
