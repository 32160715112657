import { useTheme, Theme } from '@mui/material/styles';
//
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SvgIcon from '@mui/material/SvgIcon';

// ----------------------------------------------------------------------

function CkoffIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g transform="translate(-152 -478)">
          <g>
            <g>
              <g transform="translate(152 478)">
                <g fill="none" stroke="#ddd" strokeWidth="1">
                  <rect width="24" height="24" rx="4" stroke="none" />
                  <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="none" />
                </g>
                <path
                  d="M-125.938,161.69l3.083,3.083,6.162-6.178"
                  transform="translate(133.315 -149.684)"
                  fill="none"
                  stroke="#e5e5e5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

function CkonIcon(props: any) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} sx={{ '& rect': { fill: theme.palette.primary.main } }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" rx="4" />
        <path
          d="M-125.938,161.69l3.083,3.083,6.162-6.178"
          transform="translate(133.315 -149.684)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CkoffIcon />,
        checkedIcon: <CkonIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />
      },

      styleOverrides: {
        root: {
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 20,
            height: 20
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 20,
            height: 20
          },
          svg: {
            fontSize: 20,
            '&[font-size=small]': {
              fontSize: 20
            }
          }
        }
      }
    }
  };
}
