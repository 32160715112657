import { createSlice } from '@reduxjs/toolkit';
import useAuth from 'hooks/useAuth';
import { store } from 'redux/store';
import { AuthUser, GDS_MENU } from '_szcom/types/globaltypes';
import { createFsp, callService } from '_szcom/utils/fsp';
import { getMenuTree } from '_szcom/utils/menu';

// 메뉴 정보 관리
const PGM_ID = 'CommMenuState';
export type CommMenuState = {
  gdsMenu: GDS_MENU[];
  gdsTreeMenu: any[];
  selectedMenu: GDS_MENU | undefined;
  currentLang: string;
};

const initialState: CommMenuState = {
  gdsMenu: [],
  gdsTreeMenu: [],
  selectedMenu: undefined,
  currentLang: ''
};

const slice = createSlice({
  name: PGM_ID,
  initialState,
  reducers: {
    // 초기 상태 처리
    init(state) {
      state.gdsMenu = [];
      state.gdsTreeMenu = [];
      state.selectedMenu = undefined;
      state.currentLang = '';
    },
    searchMenu(state, action) {
      state.gdsMenu = action.payload.ds;
      state.gdsTreeMenu = action.payload.dsTree;
      state.currentLang = action.payload.lang;
    },
    changeMenuLang(state, action) {
      if (action.payload.lang !== state.currentLang) {
        const tree = getMenuTree(state.gdsMenu, action.payload.lang);
        state.gdsTreeMenu = tree;
        state.currentLang = action.payload.lang;
      }
    },
    selectMenu(state, action) {
      state.selectedMenu = action.payload.ds;
    }
  }
});

export function useCommMenu() {
  const { user } = useAuth();
  const searchMenu = (lang: string) => handleSearch(user, lang);
  const initMenu = () => {
    const { dispatch } = store;
    dispatch(slice.actions.init());
  };
  const selectMenu = (ds: GDS_MENU | undefined) => async () => {
 
    const { dispatch } = store;
    dispatch(slice.actions.selectMenu({ ds }));
  };
  const changeLang = (lang: string) => changeMenuLang(lang);
  return {
    searchMenu,
    selectMenu,
    initMenu,
    changeLang
  };
}

// ----------------------------------------------------------------------
// 메뉴를 조회한다.
const handleSearch = (user: AuthUser, lang: string) => async () => {
  const { dispatch } = store;
  const fsp = createFsp();
  fsp.addSearch('cst/comm:MENU_S01');
  const { success, dataset } = await callService({
    sqlmap: fsp.requestData,
    user,
    showMsg: false
  });

  if (success && dataset && dataset.ds_menu) {
    const ds = dataset.ds_menu.data as GDS_MENU[];
    dispatch(slice.actions.searchMenu({ ds, dsTree: getMenuTree(ds, lang), lang }));
  }
};

// 언어 변경에 따른 메뉴 정보 재생성
const changeMenuLang = (lang: string) => {
  const { dispatch } = store;
  dispatch(slice.actions.changeMenuLang({ lang }));
};

// Reducer
export default slice.reducer;
