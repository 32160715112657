import { GDS_LOGIN, GDS_MENU } from '_szcom/types/globaltypes';

const SESSION_KEY = '_myapp_session';
const MENU_KEY = '_myapp_menu';
// ----------------------------------------------------------------------
// 사용자 세션 정보 저장 ( 로컬 스토리지 )
const setSession = (dsLogin: GDS_LOGIN | null) => {
  if (dsLogin) {
    localStorage.setItem(SESSION_KEY, JSON.stringify(dsLogin));
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(SESSION_KEY);
    setCurrentMenu(undefined);
    // delete axios.defaults.headers.common.Authorization;
  }
};
// 사용자 세션 정보 가져오기
const getSession = (): GDS_LOGIN | undefined => {
  const ds = localStorage.getItem(SESSION_KEY);
  if (ds) {
    return JSON.parse(ds) as GDS_LOGIN;
  }

  return undefined;
};

// 현재 메뉴를 설정한다.
const setCurrentMenu = (ds: GDS_MENU | undefined) => {
  if (ds) {
    const myData = {
      MENU_ID: ds.MENU_ID,
      PGM_PATH: ds.PGM_PATH,
      TASK_DTL_CD: ds.TASK_DTL_CD,
      RMARK: ds.RMARK
    };
    localStorage.setItem(MENU_KEY, JSON.stringify(myData));
  } else {
    localStorage.removeItem(MENU_KEY);
  }
};

// 현재 메뉴를 가져온다.
const getCurrentMenu = (): GDS_MENU | undefined => {
  const ds = localStorage.getItem(MENU_KEY);
  if (ds) {
    return JSON.parse(ds) as GDS_MENU;
  }
  return undefined;
};
export { setSession, getSession, setCurrentMenu, getCurrentMenu };
