// routes
import Router from 'routes';
// theme
import ThemeProvider from 'theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from 'components/settings';
import RtlLayout from 'components/RtlLayout';
import { ChartStyle } from 'components/chart';
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import NotistackProvider from 'components/NotistackProvider';
import ThemeColorPresets from 'components/ThemeColorPresets';
import ThemeLocalization from 'components/ThemeLocalization';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import useAuth from 'hooks/useAuth';
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ko, enUS, zhCN } from 'date-fns/locale';
import useLocales from 'hooks/useLocales';
import AlertDialog from '_szcom/components/AlertDialog';
// ----------------------------------------------------------------------

export default function App() {
  // const { isInitialized } = useAuth();
  const { currentLang } = useLocales();

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <LocalizationProvider
            locale={
              // eslint-disable-next-line no-nested-ternary
              currentLang.value === 'zh' ? zhCN : currentLang.value === 'ko' ? ko : enUS
            }
            dateAdapter={AdapterDateFns}
          >
            <AlertDialog />
            <RtlLayout>
              <NotistackProvider>
                <MotionLazyContainer>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <ChartStyle />
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <Router />
                </MotionLazyContainer>
              </NotistackProvider>
            </RtlLayout>
          </LocalizationProvider>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
