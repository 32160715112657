import { toInteger, toString } from 'lodash';
import { format, formatDistanceToNow } from 'date-fns';
import { DS_CODE, LANGS, STR_LANG, NUM_LANG } from '_szcom/types/globaltypes';
import { nodeEnv } from 'config';
// ----------------------------------------------------------------------
// 프로그램명 : 화면 표시 데이터 처리를 위한 유틸리티
//
// ----------------------------------------------------------------------
export const YMD_FORMAT = () => {
  const lang = localStorage.getItem('i18nextLng') || STR_LANG.ko;
  return LANGS[(NUM_LANG as any)[lang]].ymdFormat;
};
// 화면 표시 일자 포맷팅
export function fYmd(date: string | number | undefined, len: number = 8) {
  if (!date || toString(date).length < 8) {
    return '';
  }
  try {
    // 1630996361000
    if (typeof date === 'number') {
      return format(new Date(date), YMD_FORMAT());
    }
    return format(
      new Date(
        toInteger(date.substring(0, 4)),
        toInteger(date.substring(4, 6)) - 1,
        toInteger(date.substring(6, 8))
      ),
      YMD_FORMAT()
    );
  } catch (e) {
    console.log(date);
    console.log(e);
  }
  return date;
}
// 서버의 date 타입에 대한 포맷팅
export function fDateTime(date: string | number | Date | undefined) {
  if (!date) {
    return '';
  }
  // 시간
  if (typeof date === 'string' && date.length >= 12) {
    return format(
      new Date(
        toInteger(date.substr(0, 4)),
        toInteger(date.substr(4, 2)) - 1,
        toInteger(date.substr(6, 2)),
        toInteger(date.substr(8, 2)),
        toInteger(date.substr(10, 2))
      ),
      `${YMD_FORMAT()} HH:mm`
    );
  }
  // hh:mm p
  return format(new Date(date), `${YMD_FORMAT()} HH:mm`);
}

// 월 포맷팅
export function fYm(date: string | number | Date | undefined) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string' && date.length >= 6) {
    return toString(fYmd(`${date.substring(0, 6)}01`)).substring(0, 7);
  }

  return format(new Date(date), 'yyyy.MM');
}

// 년도 포맷팅
export function fDateYY(date: string | number | Date | undefined) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string' && date.length > 4) {
    return date.substr(0, 4);
  }
  return format(new Date(date), 'yyyy');
}

// 일자 포맷팅
export function fDateToStr(date: string | number | Date | undefined, fmt: string) {
  if (!date) {
    return '';
  }
  return format(new Date(date), fmt);
}

// 시간을 입력 받아서 데이트 타입 처리
export function fHHmmToDate(date: string | number | undefined) {
  if (!date || toString(date).length !== 4) {
    return '';
  }
  const str = toString(date);
  const d = new Date();
  d.setHours(toInteger(str.substr(0, 2)));
  d.setMinutes(toInteger(str.substr(2, 2)));
  return d;
}
// 문자 형식을 날짜 형식으로 변경
export function fYmdToDate(date: string | number | undefined) {
  if (!date) {
    return new Date();
  }
  const str = toString(date);

  return new Date(
    toInteger(str.substr(0, 4)),
    toInteger(str.substr(4, 2)) - 1,
    toInteger(str.substr(6, 2))
  );
}
export function fDate(date: string | number | Date | undefined) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string' && date.length > 8) {
    return fYmd(date);
  }
  return format(new Date(date), YMD_FORMAT());
}

// 이전, 이후 일자를 가져온다.
export function getDiffYmd(date: string, cnt: number) {
  const d = fStrToDate(date);
  if (d) {
    d.setDate(d.getDate() + cnt);
    return format(d, YMD_FORMAT());
  }
  return date;
}

// 서버의 date 타입에 대한 포맷팅
export function fStrToDate(date: string | undefined) {
  if (!date) {
    return undefined;
  }
  // 시간
  if (typeof date === 'string' && date.length >= 8) {
    return new Date(
      toInteger(date.substring(0, 4)),
      toInteger(date.substring(4, 6)) - 1,
      toInteger(date.substring(6, 8)),
      date.length >= 10 ? toInteger(date.substring(8, 10)) : 0,
      date.length >= 12 ? toInteger(date.substring(10, 12)) : 0,
      date.length >= 14 ? toInteger(date.substring(12, 14)) : 0,
    );
  }
  return undefined;
}

// 현재 시간으로 부터 경과 시간
export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

// 코드에 대한 명칭을 가져온다.
export function getCodeName(
  ds: DS_CODE[],
  cd: string | undefined,
  cdName: string | undefined = 'CD',
  colName:
    | undefined
    | {
        ko: string;
        en: string;
        zh: string;
      } = {
    ko: 'KO_NM',
    en: 'EN_NM',
    zh: 'ZH_NM'
  }
) {
  if (!ds) {
    // console.error('## 데이터셋 null ##');
    return 'not found';
  }

  const lang = localStorage.getItem('i18nextLng') || STR_LANG.ko;
  const rtn: any = ds.find((item: any) => item[cdName] === cd);
  if (rtn) {
    return rtn[(colName as any)[lang]] || rtn[colName.ko];
  }
  return cd;
}

// form에 설정하는 데이터를 초기화한다.
export function tfData(data: any) {
  if (!data) {
    return data;
  }
  const nextData: any = {};
  Object.keys(data).forEach((key) => {
    const val = data[key];
    if (!val) {
      nextData[key] = typeof val === 'number' ? 0 : '';
    } else {
      nextData[key] = val;
    }
  });
  return nextData;
}

export function tfDataWithDef(data: any, def: any) {
  const nextData: any = {};
  Object.keys(data).forEach((key) => {
    const val = data[key];
    if (!val) {
      if (def[key]) {
        nextData[key] = def[key];
      } else if (key.indexOf('_YN') >= 0) {
        nextData[key] = '0';
      } else {
        nextData[key] = typeof val === 'number' ? 0 : '';
      }
    } else {
      nextData[key] = val;
    }
  });
  return nextData;
}


// 모바일 여부: softzam useragent 포함.
export const isMobile =
  // nodeEnv.startsWith('local') ||
  /softzam/i.test(
    // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

// 신분권한 구분
export const myIdentity = (idnttCd: string) => {
  if (idnttCd === '재학생' || idnttCd === '휴학생') {
    return 'S';
  }
  if (idnttCd === '직원') {
    return 'E';
  }
  return 'P';
};