// material
import { Card, CardProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
// 프로그램명 : 그림자 없는 card 리스트로 쓸때
// ----------------------------------------------------------------------
export default function ListWrap({ children, sx, ...other }: CardProps) {
  const theme = useTheme();
  // const isLight = theme.palette.mode === 'light';
  return (
    <Card
      sx={{
        width: '100%',
        boxShadow: '0 0 0 transparent',
        marginBottom: '10px',
        background: theme.palette.background.default,
        '&:last-child': { marginBottom: '0px' },
        ...sx
      }}
    >
      {children}
    </Card>
  );
}
