import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Fab(theme: Theme) {
  return {
    MuiFab: {
      defaultProps: {
        color: 'primary'
      },

      styleOverrides: {
        root: {
          position: 'fixed',
          bottom: '30px',
          left: '50%',
          width: 'auto',
          height: 'auto',
          transform: 'translateX(-50%)',
          padding: '12px 25px',
          fontSize: '16px',
          fontWeight: 500,
          borderRadius: '24px',
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[400]
          }
        },
        primary: {
          backgroundColor: theme.palette.primary.darker,
          '&:hover': {
            backgroundColor: theme.palette.primary.darker
          }
        },
        secondary: {
          boxShadow: theme.customShadows.secondary,
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark
          }
        },
        extended: {
          '& svg': {
            marginRight: theme.spacing(1)
          }
        }
      }
    }
  };
}
