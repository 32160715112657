import { AnimatePresence, m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Backdrop, Divider, Typography, Stack, FormControlLabel, Radio } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { NAVBAR, defaultSettings } from '../../config';
//
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { IconButtonAnimate, varFade } from '../animate';
//
import ToggleButton from './ToggleButton';
import SettingMode from './SettingMode';
import SettingDirection from './SettingDirection';
import SettingColorPresets from './SettingColorPresets';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper }),
  top: 0,
  right: 0,
  bottom: 0,
  position: "fixed",
  display: "flex",
  overflow: "hidden",
  width: '280px',
  flexDirection: "column",
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  background: theme.palette.background.paper,
}));

// 환경 설정
// ----------------------------------------------------------------------
type SettingsProps = {
  // 외부 오픈 여부
  externalOpen?: boolean;
  bOpen?: boolean;
};

export default function Settings({ externalOpen = false, bOpen = false } : SettingsProps) {
  const theme = useTheme();
  const {
    themeMode,
    themeDirection,
    themeColorPresets,
    themeStretch,
    themeLayout,
    onResetSetting,
  } = useSettings();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(bOpen);
  }, [externalOpen, bOpen]);

  const notDefault =
    themeMode !== defaultSettings.themeMode ||
    themeDirection !== defaultSettings.themeDirection ||
    themeColorPresets !== defaultSettings.themeColorPresets ||
    themeLayout !== defaultSettings.themeLayout ||
    themeStretch !== defaultSettings.themeStretch;

  const varSidebar =
    themeDirection !== 'rtl'
      ? varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inRight
      : varFade({
          distance: NAVBAR.BASE_WIDTH,
          durationIn: 0.32,
          durationOut: 0.32,
        }).inLeft;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      {!externalOpen && !open && (
        <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle} />
      )}

      <AnimatePresence>
        {open && (
          <>
            <RootStyle {...varSidebar}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  padding: 1,
                  paddingLeft: '14px',
                  backgroundColor: theme.palette.background.neutral
                }}
              >
                <Typography variant="subtitle1">환경설정</Typography>

                <div>
                  <IconButtonAnimate onClick={handleClose}>
                    <Iconify
                      icon={'mdi:window-close'}
                      width={24}
                      height={24}
                      color={theme.palette.text.primary}
                    />
                  </IconButtonAnimate>
                </div>
              </Stack>
              <Scrollbar sx={{ flexGrow: 1 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Mode</Typography>
                    <SettingMode />
                  </Stack>

                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Direction</Typography>
                    <SettingDirection />
                  </Stack>
                  {/*
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Layout</Typography>
                    <SettingLayout />
                  </Stack>
                  */}
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Presets</Typography>
                    <SettingColorPresets />
                  </Stack>
                  {/*
                  <Stack spacing={1.5}>
                    <Typography variant="subtitle2">Stretch</Typography>
                    <SettingStretch />
                  </Stack>
                  <SettingFullscreen />
                  */}
                </Stack>
              </Scrollbar>
            </RootStyle>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  value: string;
};

export function BoxMask({ value }: Props) {
  return (
    <FormControlLabel
      label=""
      value={value}
      control={<Radio sx={{ display: 'none' }} />}
      sx={{
        m: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: 'absolute',
      }}
    />
  );
}
