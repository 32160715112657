import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { Reducer } from 'react';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import createReducer, { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const asyncReducers: ReducersMapObject<any, any> = {};
function addReducer<M extends Reducer<any, any>>(key: string, asyncReducer?: M) {
  if (asyncReducer) {
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(persistReducer(rootPersistConfig, createReducer(asyncReducers)));
  }
}
const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
// RootState 
const useSelector: TypedUseSelectorHook<any> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch, addReducer };

/*
const asyncReducers: ReducersMapObject<any, any> = {};
function addReducer<M extends Reducer<any, any>>(key: string, asyncReducer?: M) {
  if (asyncReducer) {
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(persistReducer(rootPersistConfig, createReducer(asyncReducers)));
  }
}
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch<AppDispatch>();
*/