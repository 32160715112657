// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  RadioGroup,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------
const RadioStyle = styled(Box)(({ theme }) => ({
  '& .MuiFormGroup-root': {
    flexWrap: 'nowrap'
  },
  '& .MuiFormControlLabel-root': {
    width: '100%',
    justifyContent: 'space-between',
    margin: '0',
    marginRight: '6px',
    padding: '3px 2px 3px 15px',
    fontSize: '15px',
    fontWeight: 500,
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: '5px',
    '&:last-child': {
      marginRight: '0px'
    }
  },
  '& .MuiFormControlLabel-label ': {
    color: theme.palette.text.grey
  },
  '& .Mui-checked + .MuiFormControlLabel-label ': {
    color: theme.palette.primary.main
  }
}));

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  options: string[];
  getOptionLabel?: string[];
  rgType?: '' | 'border';
}

export default function RHFRadioGroup({
  name,
  options,
  getOptionLabel,
  rgType,
  ...other
}: IProps & RadioGroupProps) {
  const { control } = useFormContext();
  
  if (rgType === 'border') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <RadioStyle>
            <RadioGroup {...field} row {...other}>
              {options.map((option, index) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={getOptionLabel?.length ? getOptionLabel[index] : option}
                  labelPlacement="start"
                />
              ))}
            </RadioGroup>
  
            {!!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
          </RadioStyle>
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup {...field} row {...other}>
            {options.map((option, index) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={getOptionLabel?.length ? getOptionLabel[index] : option}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );

}
