/* eslint-disable no-template-curly-in-string */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, TFunction } from 'react-i18next';
import * as Yup from 'yup';
// backend 조회
import HttpApi, { BackendOptions } from 'i18next-http-backend';
import { serviceNames } from 'config';
//
// import enLocales from './en.json';
// import koLocales from './ko.json';

// ----------------------------------------------------------------------
const userLanguage = () => {
  const lang = window.navigator.language;
  return lang.substr(0, 2).toLowerCase();
};

// 로케일 변경
export function setYupLocale(_: unknown, t: TFunction): void {
  Yup.setLocale({
    mixed: {
      default: t('MSYS00005', '잘못된 입력 값입니다.'),
      required: t('MSYS00002', '필수 입력 항목입니다.'),
      // min: t('MSYS00003', '${min}자 이상 입력해 주세요.'),
      // max: t('MSYS00004', '${max}자 까지 입력해 주세요.'),
      oneOf: t('MSYS00006', '다음 값 중 하나여야 합니다.:${values}'),
      notOneOf: t('MSYS00007', '다음 값 중 하나가 아니어야 합니다.:${values}')
    },
    number: {
      min: t('MSYS00008', '${min}보다 작게 입력해 주세요.'),
      max: t('MSYS00009', '${max}보다 크게 입력해 주세요.')
    },
    string: {
      // required: t('MSYS00002'),
      email: t('MSYS00010'),
      min: t('MSYS00003', '${min}자 이상 입력해 주세요.'),
      max: t('MSYS00004', '${max}자 까지 입력해 주세요.'),
      url: t('MSYS00014', 'url 주소 형식으로 입력해 주세요.'),
      length: t('MSYS00013', '${length}자로 입력해 주세요.'),
      // isEngNumber: t('MSYS00011', '영/숫자로만 입력해 주십시오.')
    }
  });
}

// https://github.com/i18next/i18next-http-backend
const domainApiOptions: BackendOptions = {
  loadPath: `${serviceNames.domain}?lng={{lng}}&ns={{ns}}`,
  // 'http://localhost:8080/locales/{{ns}}_{{lng}}.json',
  allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading
  // allow cross domain requests
  crossDomain: true,
  // allow credentials on cross domain requests
  withCredentials: true,
  // custom request headers sets request.setRequestHeader(key, value)
  customHeaders: {
  //  authorization: 'foo'
    'Content-Type': 'application/json; charset=utf-8'
  },

  //  request: function (options, url, payload, callback) { },
  // queryStringParams: { v: '1.3.5' },

  requestOptions: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default'
  },
  reloadInterval: false // can be used to reload resources in a specific interval (useful in server environments)
};

const domainApi = new HttpApi(null, domainApiOptions);
i18n
  .use(LanguageDetector)
  .use(domainApi) // 서버 조회 public/locales/en,ko/ns명.json
  .use(initReactI18next)
  .init(
    {
      // resources: {
      //  en: { translations: enLocales },
      //  ko: { translations: koLocales }
      // },
      react: {
        useSuspense: false //   <---- this will do the magic
      },
      lng: localStorage.getItem('i18nextLng') || userLanguage() || 'ko',
      fallbackLng: 'ko',
      debug: false,
      ns: ['domain'],
      defaultNS: 'domain',
      interpolation: {
        escapeValue: false
      }
    },
    setYupLocale
  );

export default i18n;
