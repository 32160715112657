// 웹 쿠키를 기본으로 하는 사용자 로그인 세션
import { createContext, ReactNode, useEffect, useReducer } from 'react';
// @types

// utils
import axios from 'utils/axios';
import { serviceNames, STORE_USER_ID_KEY, STORE_WEB_ID_KEY } from 'config';
import * as fsp from '_szcom/utils/fsp';
import { setSession } from '_szcom/utils/global';
import {
  ActionMap,
  AuthState,
  AuthUser,
  WebSessionContextType,
  GDS_LOGIN,
  LoginParams
} from '_szcom/types/globaltypes';
import { persistor } from 'redux/store';
import { isMobile } from '_szcom/utils/dataUtils';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  INIT = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT'
}

type WebSessionAuthPayload = {
  [Types.INIT]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.LOGIN]: {
    user: AuthUser;
  };
  [Types.LOGOUT]: undefined;
};

type WebSessonActions = ActionMap<WebSessionAuthPayload>[keyof ActionMap<WebSessionAuthPayload>];

const reducer = (state: AuthState, action: WebSessonActions) => {
  if (action.type === Types.INIT) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }
  if (action.type === Types.LOGIN) {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false,
      user: null
    };
  }
  return state;
};

const AuthContext = createContext<WebSessionContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        // 정보 있는지 확인해서 로그인 처리 필요
        let dsLogin = null;
        // 세션 서버에 존재하는지 확인
        const fspReq = fsp.defaultFsp();
        const response = await axios.post(serviceNames.checkSession, fspReq);
        const { code, dataset } = fsp.parseRespons(response.data);
        if (code === -200 || !dataset.ds_login) {
          dsLogin = undefined;
        } else {
          dsLogin = dataset.ds_login.data[0] as GDS_LOGIN;
        }

        // 로그인 정보 확인
        if (dsLogin) {
          dispatch({
            type: Types.INIT,
            payload: {
              isAuthenticated: true,
              user: dsLogin
            }
          });
        } else {
          dispatch({
            type: Types.INIT,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.INIT,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // 로그인 처리 수행
  const login = async ({
    USER_ID,
    PWD,
    DVIC_ID = '',
    WEB_ID = '',
    SITE_KEY = '',
    SAVE_USER_ID = true,
    USE_AUTO_LOGIN = false,
    CHNL_TP = isMobile ? 'M' : 'W'
  }: LoginParams) => {
    const v = {
      USER_ID,
      PWD,
      DVIC_ID,
      SITE_KEY,
      WEB_ID,
      CHNL_TP,
    };

    // 1. 로그인 처리
    const fspReq = fsp.defaultFsp();
    const { success, code, message, dataset } = await fsp.callService({ url: serviceNames.login, sqlmap: fspReq, variable: v });
    
    if (success) {
      const ds = dataset.ds_login.data as GDS_LOGIN[];
      const user = Array.isArray(ds) ? ds[0] : ds;
      await persistor.purge();
      setSession(user);

      if (isMobile) {
        // 앱 설정 정보 저장하기
        const setSharedValues = (json: any, dsLogin: GDS_LOGIN) => {
          window.fspmob({ 
            api: 'sharedData',
            cmd: 'setValues',
            param: [json],
            callback: (errorCode: number, message: any) => {
              // 세션 정보 설정
              errorCode === 0 && makeWebSession(dsLogin);
            }
          });
        };

        // 세션 정보 저장하기 
        const makeWebSession = (dsLogin: GDS_LOGIN) => {
          window.fspmob({ 
            api: 'mapp',
            cmd: 'makeWebSession',
            param: [dsLogin]
          });
        };
        
        const json: any = {
          userId : v.USER_ID,
          pwd: v.PWD,
          useAutoLogin: USE_AUTO_LOGIN ? "true" : "false",
          useSaveUserId: SAVE_USER_ID ? "true" : "false"
        };
        setSharedValues(json, user);
      } else {
        if (SAVE_USER_ID) {
          localStorage.setItem(STORE_USER_ID_KEY, v.USER_ID);
        } else {
          localStorage.removeItem(STORE_USER_ID_KEY);
        }
        localStorage.setItem(STORE_WEB_ID_KEY, v.WEB_ID);
      }

      dispatch({
        type: Types.LOGIN,
        payload: {
          user
        }
      });
    }
    return { success, code, message, dataset };
  };

  const logout = async () => {
    // 1. logout 처리
    const fspReq = fsp.defaultFsp();
    await axios.post(serviceNames.logout, fspReq);
    // const { code } = fsp.parseRespons(response.data);
    await persistor.purge();
    setSession(null);
    // 앱 설정 정보 저장하기 : 비밀번호 지우기
    const setSharedValues = (json: any) => {
      window.fspmob({ 
        api: 'sharedData',
        cmd: 'setValues',
        param: [json],
        callback: (errorCode: number, message: any) => {
          errorCode === 0 && removeWebSession();
        }
      });
    };
    // 세션 정보 삭제하기 
    const removeWebSession = () => {
      window.fspmob({ 
        api: 'mapp',
        cmd: 'removeWebSession',
        param: [],
        callback: (errorCode: number, message: any) => {
          dispatch({ type: Types.LOGOUT });
        }
      });
    };
    if (isMobile) {
      setSharedValues({ pwd: '' });
    } else {
      dispatch({ type: Types.LOGOUT });
    }
  };

  const resetPassword = (userid: string) => {};
  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cookie',
        user: {
          USER_ID: state?.user?.USER_ID,
          USER_NM: state?.user?.USER_NM,
          IDNTT_CD: state?.user?.IDNTT_CD,
          photoURL: '',
          displayName: state?.user?.USER_NM,
        },
        login,
        logout,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
