import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { SERVICE_URL } from 'config';
import { toInteger } from 'lodash';

// ----------------------------------------------------------------------
// https://github.com/axios/axios/blob/master/test/typescript/axios.ts
export const axiosDefaultConfig: AxiosRequestConfig = {
  baseURL: SERVICE_URL,
  timeout: 60 * 1000, // 30초
  withCredentials: true
  // headers: {
  //  'Access-Control-Allow-Origin': '*',
  //  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //  'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
  //  'Test-Header': 'bbbbbb'
  // }
};

export type HttpApiErrorType = {
  code: number;
  message: string;
  error: AxiosError | undefined;
  responseData: any;
  status: number;
};
const axiosInstance = axios.create(axiosDefaultConfig);
axiosInstance.interceptors.response.use(
  (response) => (response),
  (error) => {
    const status =
      error.code === 'ECONNABORTED' ? 405 : (error.response && error.response.status) || -400;
    const result = error.response && error.response.data;
    // 화면 기본 에러 코드 정의
    const errorData: HttpApiErrorType = {
      code: -9999,
      message: `${error.message || '서버에 연결할 수 없습니다.'}(${status})${Math.random()}`,
      error,
      responseData: result,
      status
    };
    if (status === 200) {
      const myCode = result.ErrorCode;
      if (toInteger(myCode) !== 0) {
        errorData.code = toInteger(myCode);
        errorData.message = `서버 처리 중 오류가 발생했습니다.(${result.ErrorMsg})`;
      }
    } else if (status === 404) {
      console.log(`세션 정보가 없습니다: ${error.response}`);
      errorData.code = -100;
      errorData.message = '로그인 정보가 없습니다.(404)';
    } else if (status === 500 || status === 400) {
      if (errorData.responseData) {
        const { message: sMsg, code } = errorData.responseData;
        if (sMsg && /[\s\S]*?ORA-\d.*?:\s*/.test(sMsg)) {
          const t = sMsg
            .replace(/[\s\S]*?ORA-\d.*?:\s*/, '')
            .replace(/\s*ORA-\d*[\s\S]*/, '')
            .trim();
          errorData.code = toInteger(code);
          errorData.message = `[서비스 오류] ${t}`;
        } else {
          errorData.message = `서버 처리 중 오류가 발생했습니다.(${status})`;
        }
      }
    } else if (status === 403 || status === 405) {
      // 권한 없음
      errorData.code = -200;
      errorData.message = `서비스 처리 권한이 없습니다. 로그인 정보를 확인하세요.(${status})`;
    } else if (status === 401) {
      // SSO Logout
      errorData.code = -100;
      errorData.message = `서비스 처리 권한이 없습니다. 로그인 정보를 확인하세요.(${status})`;
    }
  
    console.log(errorData);
    return Promise.reject(errorData);
  }
);

export default axiosInstance;
