import { useState } from 'react';

import {
  Stack,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import { LoadingButton } from '@mui/lab';
import PopupWrap from "_szcom/popup/PopupWrap";
import PopupHeader from "_szcom/popup/PopupHeader";
import PopupMain from "_szcom/popup/PopupMain";
import Iconify from 'components/Iconify';
import { useDispatch } from 'redux/store';
import { makeKey } from './CST0003_A01';
import { DS_PRVC, LoginFormValues } from "pages/comm/auth/Login_T01";
import CST0003_L01 from './CST0003_L01';
// ----------------------------------------------------------------------
// 2차 인증 처리 ( 로그인 완료 후 관련 정보 미존재 시 ) : CST0003
// ----------------------------------------------------------------------

export type CST0003Props = {
  onClose?: (finish: boolean) => void;
  loginFormData: LoginFormValues;
  dsPrvc: DS_PRVC | undefined;
  afterSendKey?: (finish: boolean) => void;
};

export default function CST0003({ onClose, loginFormData, dsPrvc } : CST0003Props) {
  // 전송 완료 여부
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleClose = () => {
    onClose && onClose(false);
  };
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const dispatch = useDispatch();
  // 키 생성
  const handleMakeKey = async () => {
    if (dsPrvc && dsPrvc.MOBL_NO) {
      const { success } = await dispatch(makeKey(loginFormData, dsPrvc?.MOBL_NO));
      setSubmitting(success);
      if (!success) {
        setErrorMsg('인증번호 발송에 실패했습니다. 잠시 후 다시 처리 해 주십시오.');
      } else {
        setErrorMsg(undefined);
      }
    } else {
      setErrorMsg('시스템에 휴대전화번호가 등록되어 있지 않습니다. 확인하여 주십시오.');
    }
  };

  // 번호입력 후 서버 확인 완료
  const handleFinish = (finish: boolean) => {
    setSubmitting(false);
    if (finish) {
      onClose && onClose(finish);
    }
  };

  return (
    <PopupWrap onClose={handleClose}>
      <PopupHeader>본인 확인</PopupHeader>
      <PopupMain>
      {errorMsg && (
        <Box pt={12} px={4}>
          <Typography
            textAlign="center"
            pb={3}
            variant="subtitle1"
          >
            {errorMsg}
          </Typography>
        </Box>
      )}
      
        {!errorMsg && dsPrvc && (
          <Box pt={12} px={4}>
            <Typography
              textAlign="center"
              pb={3}
              color="primary"
              variant="h1"
            >
              {dsPrvc.MOBL_NO.substring(0, dsPrvc.MOBL_NO.length -2)}{'**'}
            </Typography>

            {!submitting && (
              <Stack flexDirection={'row'} spacing={0.5} justifyItems={'center'} alignItems={'center'}  sx={{ mb: 3 }}>
                <IconButton>
                  <Iconify icon={'mdi:information'} width={16} height={16}/>
                </IconButton>
                <Typography textAlign="center" variant="caption">
                  시스템에 등록되어 있는 휴대전화 번호로 인증 번호가 전송됩니다. <br />
                  번호가 잘못되었거나, 변경된 경우 교무팀(062-230-8043)로 문의하여 주십시오. <br />
                </Typography>
              </Stack>
            )}

            {!submitting && dsPrvc.MOBL_NO && (
              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                onClick={() => handleMakeKey()}
              >
                인증번호 요청
              </LoadingButton>
            )}
            {submitting && <CST0003_L01 loginFormData={loginFormData} dsPrvc={dsPrvc} onClose={handleFinish} />}
          </Box>
        )}  
      </PopupMain>
    </PopupWrap>
  );
}
