
import { createFsp, callService } from '_szcom/utils/fsp';
import { serviceNames } from 'config';
import { LoginFormValues } from 'pages/comm/auth/Login_T01';
// ----------------------------------------------------------------------
// 2차 인증 번호를 생성한다.
export const makeKey = (loginData:LoginFormValues, mobileNo: string) => async () => {
  const fsp = createFsp();
  const { success, code, message, dataset } = await callService({
    url: `${serviceNames.login}/makeKey`,
    sqlmap: fsp.requestData,
    variable: {
      USER_ID: loginData.USER_ID,
      DVIC_ID: loginData.DVIC_ID,
      WEB_ID: loginData.WEB_ID,
      CHNL_TP: loginData.CHNL_TP,
      SITE_KEY: loginData.SITE_KEY,
      MOBL_NO: mobileNo
    },
    dataset: {}
  });
  // 개발에서 테스트할때 인증번호 보이게 하기 위해서 만듬. 2022.02.08
  //alert('인증번호: ' + dataset.ds_list.data[0].AUTH_KEY);
  return { success, code, message };
};

// 2차 인증 및 개인정보 처리 완료
export const sendKey = (loginData:LoginFormValues, authCode: string) => async () => {
  const fsp = createFsp();
  const { success, code, message } = await callService({
    url: `${serviceNames.login}/comp2auth`,
    sqlmap: fsp.requestData,
    variable: {
      USER_ID: loginData.USER_ID,
      DVIC_ID: loginData.DVIC_ID,
      WEB_ID: loginData.WEB_ID,
      CHNL_TP: loginData.CHNL_TP,
      SITE_KEY: loginData.SITE_KEY,
      CODE: authCode
    },
    dataset: {}
  });

  return { success, code, message };
};
