import Yup from '_szcom/utils/yup-extended';
import { useState, useEffect } from 'react';
import { toInteger } from 'lodash';
import { useTheme } from '@mui/material/styles';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Box, IconButton, InputAdornment, Alert, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useMessages from 'hooks/useMessages';
// components
import { DialogAnimate } from 'components/animate';
import Iconify from 'components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from 'components/hook-form';
import { isMobile } from '_szcom/utils/dataUtils';
import uuidv4 from 'utils/uuidv4';
import { STORE_USER_ID_KEY, STORE_WEB_ID_KEY } from 'config';
import CST0002 from '../../CST/CST00/CST0002';
import CST0003 from '../../CST/CST00/CST0003';
import { LoginFormValues, DS_PRVC } from './Login_T01';
// ----------------------------------------------------------------------

// 로그인
export default function LoginForm() {
  const theme = useTheme();
  const { login } = useAuth();
  const { info } = useMessages();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    USER_ID: Yup.string().required(),
    PWD: Yup.string().required()
  });

  const defaultValues = {
    USER_ID: localStorage.getItem(STORE_USER_ID_KEY) || '',
    PWD: '',
    WEB_ID: localStorage.getItem(STORE_WEB_ID_KEY) || uuidv4(),
    CHNL_TP: isMobile ? 'M' : 'W',
    SAVE_AUTO_LOGIN: true,
    SAVE_USER_ID: true
  };
  const methods = useForm<LoginFormValues>({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    setValue,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = methods;

  const onSubmit = async (data: LoginFormValues) => {
    clearErrors();
    const { success, message, code, dataset } = await login(data);
    if (success) {
      info('로그인을 완료했습니다.');
    } else {
      switch (toInteger(code)) {
        case -211: // 사용자 또는 비밀번호가 맞지 않음. -211
          if (isMountedRef.current) {
            setError('ErrorMsg', { message: `[${code}] : 사용자 정보가 존재하지 않습니다.` });
          }
          break;
        case -212: // 2차 인증 및 개인정보 동의 (함께 처리되도록 한다.)
          if (dataset && dataset.ds_prvc && dataset.ds_prvc.data.length > 0) {
            const dsPrvc = dataset.ds_prvc.data[0] as DS_PRVC;
            setDsPrvc(dsPrvc);
            setProc(!dsPrvc.PRVC_AGR_YN || dsPrvc.PRVC_AGR_YN === 'N' ? 'A' : 'C');
          } else {
            if (isMountedRef.current) {
              // error(message);
              setError('ErrorMsg', { message: `[${code}] : ${message}` });
            }
          }
          break;
        default:
          if (isMountedRef.current) {
            // error(message);
            setError('ErrorMsg', { message: `[${code}] : ${message}` });
          }
          break;
      }
    }
  };

  // 1. 디바이스 정보 가져오기
  const callbackGetDeviceInfo = (errorCode: number, message: any) => {
    if (message) {
      setValue('DVIC_ID', message.deviceID);
      setValue('SITE_KEY', message.siteKey);
      getSharedValues();
    }
  };
  useEffect(() => {
    window.fspmob({ api: 'mapp', cmd: 'getAppInfo', param: [], callback: callbackGetDeviceInfo });
  }, []);

  // 2. 앱 설정 정보 가져오기
  const getSharedValues = () => {
    window.fspmob({
      api: 'sharedData',
      cmd: 'getValues',
      param: ['userId,pwd,useAutoLogin,useSaveUserId'],
      callback: (errorCode: number, message: any) => {
        // 저장
        if (errorCode === 0) {
          const result = message.result as any;
          setValue('USER_ID', result.userId);
          setValue('PWD', result.pwd);
          if (result.userId) {
            // 최초인 경우에만 처리
            setValue('SAVE_USER_ID', result.useSaveUserId); // 아이디 저장
          }
          setValue('SAVE_AUTO_LOGIN', result.useAutoLogin); // 자동 로그인
        }
        // 데이터 존재시 자동 로그인 처리
        if (getValues('USER_ID') && getValues('PWD')) {
          onSubmit(getValues());
        }
      }
    });
  };

  // 개인정보 동의 및 2가 인증 관련
  const [proc, setProc] = useState<'A' | 'C' | undefined>();
  // 2차 인증 관련 데이터
  const [dsPrvc, setDsPrvc] = useState<DS_PRVC | undefined>();

  // 1. 개인정보 동의
  const handleCloseAgree = (success: boolean) => {
    // 2차 인증이 완료되면
    if (success) {
      setProc('C'); // 2차 인증
    } else {
      setError('ErrorMsg', { message: '개인정보제공동의를 [동의]해야 합니다.' });
      setProc(undefined);
    }
  };
  // 2. 2차 인증 화면
  const handleCloseCerity = (success: boolean) => {
    setProc(undefined);
    if (success) {
      // 다시 로그인 처리
      onSubmit(getValues());
    } else {
      setError('ErrorMsg', { message: '본인확인을 위한 2차 인증을 [완료]해야 합니다.' });
    }
  };

  if (proc === 'C') {
    return (
      <DialogAnimate fullScreen open={proc === 'C'}>
        <CST0003 onClose={handleCloseCerity} loginFormData={getValues()} dsPrvc={dsPrvc} />
      </DialogAnimate>
    );
  }
  if (proc === 'A') {
    return (
      <DialogAnimate fullScreen open={proc === 'A'}>
        <CST0002 onClose={handleCloseAgree} />
      </DialogAnimate>
    );
  }

  return (
    <Box sx={{ width: '100%', marginTop: '12vw' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack alignItems="center" justifyContent="center" flexDirection="column" spacing={1.5}>
          {!!errors.ErrorMsg && (
            <Alert severity="error" sx={{ width: '100%' }}>
              {errors.ErrorMsg?.message}
            </Alert>
          )}
          <RHFTextField
            label="아이디"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:account-outline" />
                </InputAdornment>
              )
            }}
            name="USER_ID"
            sx={{ '& .MuiInputBase-root	': { background: theme.palette.background.paper } }}
          />

          <RHFTextField
            name="PWD"
            label="비밀번호"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="mdi:lock-outline" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ '& .MuiInputBase-root	': { background: theme.palette.background.paper } }}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            로그인
          </LoadingButton>

          <Stack
            direction="row"
            alignContent="center"
            justifyContent="flex-start"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <RHFCheckbox name="SAVE_USER_ID" label="아이디 저장" />
            {isMobile && <RHFCheckbox name="SAVE_AUTO_LOGIN" label="자동 로그인" />}
          </Stack>
          <Typography
            component="a"
            variant="body3"
            sx={{
              color: 'gray',
              textDecoration: 'underline',
            }}
            href="https://www.cst.ac.kr/page/?site=basic&mn=1402"
            rel="noopener"
            target="_blank"
          >
            개인정보처리방침
          </Typography>
        </Stack>
      </FormProvider>
    </Box>
  );
}
