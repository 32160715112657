import { createSlice } from '@reduxjs/toolkit';
import { store } from 'redux/store';

// alert창
const PGM_ID = 'AlertDialogState';
export type AlertDialogState = {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  yesLabel?: string;
  noLabel?: string;
  type: '' | 'alert' | 'confirm';
  cnt: number;
};

const initialState: AlertDialogState = {
  message: '',
  onConfirm: undefined,
  type: '',
  cnt: 0
};

const slice = createSlice({
  name: PGM_ID,
  initialState,
  reducers: {
    confirm(state, action) {
      state.cnt++;
      state.type = 'confirm';
      state.onConfirm = action.payload.onConfirm;
      state.onCancel = action.payload.onCancel;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.yesLabel = action.payload.yesLabel;
      state.noLabel = action.payload.noLabel;
    },
    alert(state, action) {
      state.cnt++;
      state.type = 'alert';
      state.onConfirm = action.payload.onConfirm;
      state.onCancel = undefined;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.yesLabel = action.payload.yesLabel;
      state.noLabel = '';
    }
  }
});

type AlertDialogProps = {
  title?: string;
  yesLabel?: string;
  onConfirm?: () => void;
};
type ConfirmDialogProps = AlertDialogProps & {
  onCancel?: () => void;
  noLabel?: string;
};
// ----------------------------------------------------------------------
// 확인창
export const confirm = (
  message: string,
  { title, yesLabel, onCancel, onConfirm, noLabel }: ConfirmDialogProps
) => {
  const { dispatch } = store;
  dispatch(slice.actions.confirm({ message, onConfirm, onCancel, yesLabel, noLabel, title }));
};

// alert창
export const alert = (message: string, { onConfirm, title, yesLabel }: AlertDialogProps) => {
  const { dispatch } = store;
  dispatch(slice.actions.alert({ message, onConfirm, yesLabel, title }));
};

// Reducer
export default slice.reducer;
