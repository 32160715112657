import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Card(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const boxShadow = `0 6px 16px 0 ${alpha(isLight ? '#7E9190' : theme.palette.common.black, 0.1)}`;

  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow,
          position: 'relative',
          borderRadius: '8px',
          zIndex: 0, // Fix Safari overflow: hidden with border radius,
          border: `1px solid ${theme.palette.background.divider}`
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: {
          variant: 'body2',
          marginTop: theme.spacing(0.5)
        }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3)
        }
      }
    }
  };
}
