// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from 'hooks/useOffSetTop';
import useResponsive from 'hooks/useResponsive';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { HEADER, NAVBAR } from 'config';
// components
import Logo from 'components/Logo';
import Iconify from 'components/Iconify';
import { IconButtonAnimate } from 'components/animate';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------
type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const MainHeader = styled(Stack)(({ theme }) => ({
  padding: '12px 14px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
// ----------------------------------------------------------------------
type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  // const isDesktop = useResponsive('up', 'lg');
  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <MainHeader>
        <Logo sx={{ width: '140px', height: '28px' }} />
        <Stack direction="row" spacing={1.8}>
          {/* <LanguagePopover aria-label="언어선택" /> */}
          <NotificationsPopover />
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ width: 24, height: 24, padding: 0 }}
          >
            <Iconify icon="mdi:menu" sx={{ width: 24, height: 24 }}  color="text.primary" />
          </IconButtonAnimate>
        </Stack>
      </MainHeader>
    </RootStyle>
  );
}
