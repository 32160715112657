// @mui
import { Box, BoxProps } from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------
// 프로그램명 : 팝업헤더
// ----------------------------------------------------------------------
export default function PopupHeader({ children, sx, ...other }: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '52px',
        borderBottom: `1px solid ${theme.palette.background.divider}`,
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 500,
        ...sx
      }}
    >
      {children}
    </Box>
  );
}
