import { m } from 'framer-motion';
import { useEffect, useMemo } from 'react';
// material
import { alpha, styled, Theme} from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import useMessages from 'hooks/useMessages';
import ProgressBar from 'components/ProgressBar';
// ----------------------------------------------------------------------
// 프로그램명 : 서비스 호출 시 Wating 표시 창
//
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

export default function LoadingData({ ...other }) {
  
  // 처리중입니다. 메시지 처리
  const { getMsg } = useMessages();
  const msg = getMsg('MCI00007');
  return (
    <>
      <ProgressBar />
      <RootStyle {...other}>
        <Typography sx={{ width: 'auto', fontSize: '1.1rem' }}>{msg}</Typography>
        <Box
          component={m.div}
          animate={{
            scale: [1.2, 1, 1, 1.2, 1.2],
            rotate: [270, 0, 0, 270, 270],
            opacity: [0.25, 1, 1, 1, 0.25],
            borderRadius: ['25%', '25%', '50%', '50%', '25%']
          }}
          transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
          sx={{
            width: 100,
            height: 100,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`
          }}
        />

        <Box
          component={m.div}
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
            rotate: [0, 270, 270, 0, 0],
            opacity: [1, 0.25, 0.25, 0.25, 1],
            borderRadius: ['25%', '25%', '50%', '50%', '25%']
          }}
          transition={{
            ease: 'linear',
            duration: 3.2,
            repeat: Infinity
          }}
          sx={{
            width: 120,
            height: 120,
            borderRadius: '25%',
            position: 'absolute',
            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`
          }}
        />
      </RootStyle>
    </>
  );
}
