import SvgIconStyle from 'components/SvgIconStyle';
import { GDS_MENU } from '_szcom/types/globaltypes';
import { PATH_MAIN } from 'routes/paths';
import { toInteger } from 'lodash';
// ----------------------------------------------------------------------
// 메뉴 처리 관련 유틸리티 클래스
// ----------------------------------------------------------------------
const ICONS_LIST = [
  'analytics',
  'blog',
  'calendar',
  'cart',
  'chat',
  'ecommerce',
  'kanban',
  'mail',
  'user'
];
// 메뉴 표시 아이콘 가져오기
const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

// 메뉴 아이콘 가져오기
export const getMenuIcon = (name: string) => (name ? getIcon(`ic_${name}`) : getIcon('ic_blog'));

// 메뉴 목록 가져오기
export const getIconList = () => ICONS_LIST;

// 메뉴 경로를 가져온다.
export const getMenuLink = (menu: GDS_MENU) => {
  if (menu.UP_MENU_ID) {
    return `${PATH_MAIN.menu}/${menu.UP_MENU_ID}/${menu.MENU_ID}`;
  }

  return `${PATH_MAIN.menu}/${menu.MENU_ID}`;
}
// 메뉴 트리 생성
export const getMenuTree = (menu: GDS_MENU[], lang: string) => {
  const nList: any[] = [];
  const mapName: any ={};
  if (!menu || menu.length < 1) {
    return nList;
  }

  // eslint-disable-next-line array-callback-return
  menu.map((item, index) => {
    const menu = {
      menuId: item.MENU_ID,
      // eslint-disable-next-line no-nested-ternary
      title: lang === 'en' ? item.MENU_ENG_NM : lang === 'zh' ? item.MENU_CHN_NM : item.MENU_NM,
      path: item.VIEW_CLS ==='P'? item.PGM_PATH : `${PATH_MAIN.menu}/${item.MENU_ID}`,
      icon: getMenuIcon(item.PARAM),
      children: []
    };

    if (item.VIEW_CLS !== 'A') {
      if (toInteger(item.MENU_LVL) === 1) {
        let parentObj: any = {
          subheader: menu.title,
          subheaderKey: menu.menuId,
          path: menu.path,
          items: []
        }; // 최상위 메뉴
        nList.push(parentObj);
        // const menuData = { ...menu };
        // parentObj.items.push(menuData);
        mapName[item.MENU_ID] = parentObj;
      } else {
        // const prevObj = nList.find((_pm) => _pm.subheaderKey === item.UP_MENU_ID);
        const prevObj = mapName[item.UP_MENU_ID || ''];
        
        if(prevObj) {
         
           const page  = item.VIEW_CLS ==='P'? item.PGM_PATH : `${prevObj.path}/${item.MENU_ID}`;
           //item.PGM_PATH
           const menuData = { ...menu };
          (prevObj.items ? prevObj.items : prevObj.children).push({ ...menu, path: page });
        }
      }
    } // end A 
  });
  return nList;
};
