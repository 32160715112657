import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import Scrollbar from 'components/Scrollbar';
import { NavSectionVertical } from 'components/nav-section';
import { useSelector } from 'redux/store';
import { CommMenuState } from 'redux/slices/commMenuState';
import Iconify from 'components/Iconify';
import { IconButtonAnimate } from 'components/animate';
import useAuth from 'hooks/useAuth';
import useMessages from 'hooks/useMessages';
import Settings from 'components/settings';
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------
type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: Props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const { gdsTreeMenu: menuList } = useSelector(
    (state: { CommMenuState: CommMenuState }) => state.CommMenuState
  );

  useEffect(() => {
    if (isOpenSidebar) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // 화면 닫기
  const handleClose = () => {
    onCloseSidebar();
    setSettingsOpen(false);
  };
  // 설정화면
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  // 로그아웃 처리
  const { logout } = useAuth();
  const { confirm } = useMessages();
  const handleLogout = () => {
    confirm('로그아웃 하시겠습니까?', {
      onConfirm: () => {
        logout();
      }
    });
  };

  const renderContent = (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={0.2}
        sx={{
          padding: 1,
          backgroundColor: theme.palette.background.neutral
        }}
      >
        <IconButtonAnimate onClick={(e) => handleLogout()}>
          <Iconify
            icon={'mdi:logout-variant'}
            width={24}
            height={24}
            color={theme.palette.text.primary}
          />
        </IconButtonAnimate>
        <IconButtonAnimate
          onClick={(e) => {
            setSettingsOpen(!settingsOpen);
          }}
        >
          <Iconify
            icon={'mdi:cog-outline'}
            width={24}
            height={24}
            color={theme.palette.text.primary}
          />
        </IconButtonAnimate>
        <IconButtonAnimate onClick={(e) => handleClose()}>
          <Iconify
            icon={'mdi:window-close'}
            width={24}
            height={24}
            color={theme.palette.text.primary}
          />
        </IconButtonAnimate>
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <NavSectionVertical navConfig={menuList} isCollapse={isCollapse} />
      </Scrollbar>
    </>
  );

  return (
    <RootStyle
      sx={{
        width: '0px',
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      {isOpenSidebar && (<Settings externalOpen={true} bOpen={settingsOpen} />)}
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{ sx: { width: '280px' } }}
        anchor={'right'}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
