import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '0 !important',
          '&.Mui-expanded': { margin: '0' },
          '&.Mui-disabled': {
            backgroundColor: 'transparent'
          },
          '&:before': {
            width: '0'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: theme.palette.background.default,
          paddingLeft: '4px',
          paddingRight: theme.spacing(1),
          '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.action.disabled,
            '& .MuiTypography-root': {
              color: 'inherit'
            }
          },
          '&.Mui-expanded': { minHeight: '0' },
          '& .MuiAccordionSummary-content': {
            '&.Mui-expanded': { margin: '12px 0' }
          }
        },
        expandIconWrapper: {
          color: theme.palette.grey[500],
          '&.Mui-expanded': {
            color: theme.palette.primary.main
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0'
        }
      }
    }
  };
}
