export enum DataRowType {
  ROW_TYPE_NORMAL = 'normal',
  ROW_TYPE_INSERTED = 'insert',
  ROW_TYPE_UPDATED = 'update',
  ROW_TYPE_DELETED = 'delete'
}

// DataTypes
export enum DataTypes {
  undefined = 'undefined',
  null = 'null',
  string = 'string',
  int = 'int',
  boolean = 'boolean',
  long = 'long',
  float = 'float',
  double = 'double',
  bigdecimal = 'bigdecimal',
  bigint = 'number',
  date = 'date',
  time = 'time',
  datatime = 'datatime',
  blod = 'blod',
  file = 'file',
  number = 'number',
  any = 'string',
  symbol = 'string',
  object = 'string',
  function = 'string'
}

export type DatasetBase = {
  _rowState?: DataRowType;
};
