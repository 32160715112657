import useLocalStorage from './useLocalStorage';

// 뱃지 건수 관리
export default function useBadgeCount() {
  const [pushData, setPushData] = useLocalStorage('fspBadge', {
    badgeCount: 0
    // lang: 'ko'
  });

  const setBadgeCount = (count: number, fspmob: boolean = true) => {
    setPushData({ ...pushData, badgeCount: count });
    if (fspmob) {
      window.fspmob({ api: 'notification', cmd: 'setBadgeCount', param: [count] });
    }
  };

  return [pushData.badgeCount, setBadgeCount, pushData, setPushData];
}

// 푸시 메시지 아이디 설정
export const getShowPushMsgId = (bRemove: boolean = false) => {
  const msgId = localStorage.getItem('fspMsgId');
  bRemove && localStorage.setItem('fspMsgId', '');
  return msgId;
};
