import { createSlice } from '@reduxjs/toolkit';
import useAuth from 'hooks/useAuth';
import useBadgeCount from 'hooks/useBadgeCount';
import { store } from 'redux/store';
import { AuthUser } from '_szcom/types/globaltypes';
import { DS_PUSH } from '_szcom/types/push';
import { createFsp, callService } from '_szcom/utils/fsp';

// 푸시 데이터 관리
const PGM_ID = 'CommPushState';
export type CommPushState = {
  currentMsgId: string; // 현재 선택 푸시 아이디
  dsUnReadList: DS_PUSH[];
};

const initialState: CommPushState = {
  currentMsgId: '',
  dsUnReadList: []
};

const slice = createSlice({
  name: PGM_ID,
  initialState,
  reducers: {
    // 초기 상태 처리
    init(state) {
      state.currentMsgId = '';
      state.dsUnReadList = [];
    },
    clearMsgId(state) {
      state.currentMsgId = '';
    },
    // 푸시 데이터 조회
    searchUnRead(state, action) {
      const ds = action.payload.dsUnReadList;
      state.dsUnReadList = ds;
    }
  }
});

export function useCommPush() {
  const { user } = useAuth();
  const [, setBadgeCount] = useBadgeCount();
  const searchUnRead = () => _searchUnRead(user, setBadgeCount);
  const init = () => {
    const { dispatch } = store;
    dispatch(slice.actions.init());
  };
  const clearMsgId = () => {
    const { dispatch } = store;
    dispatch(slice.actions.init());
  };

  return {
    initPushData: init,
    searchUnRead
  };
}

// ----------------------------------------------------------------------
// 미확인 메시지를 조회한다.
const _searchUnRead = (user: AuthUser, setBadgeCount: any) => async () => {
  const { dispatch } = store;
  const fsp = createFsp();
  fsp.addSearch('cst/comm:PUSH_S01');
  const { success, dataset } = await callService({
    serviceId: `${PGM_ID}_SEARCH`,
    sqlmap: fsp.requestData,
    showMsg: false,
    user
  });

  if (success && dataset && dataset.ds_push) {
    const dsUnReadList = dataset.ds_push.data as DS_PUSH[];
    setBadgeCount(dsUnReadList.length, true);
    dispatch(slice.actions.searchUnRead({ dsUnReadList }));
  }
};

// Reducer
export default slice.reducer;
