import { enUS, koKR, zhCN } from '@mui/material/locale';
import { Datasets } from '_szcom/utils/fsp';
// 사용자 세션 관련
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};
// 사용자 정보 객체
export type AuthUser = null | Record<string, any>;
// 사용자 상태
export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
};
// 웹 세션으로 관리하는 컨텍스트 객체
export type WebSessionContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'cookie';
  login: (params: LoginParams) => Promise<{ success: boolean; code: number; message: string; dataset: Datasets; }>;
  logout: VoidFunction;
  resetPassword: (userid: string) => void;
  // updateProfile: VoidFunction;
};
// 로그인 데이터
export type LoginParams = {
  USER_ID: string;
  PWD?: string;
  DVIC_ID?: string; // 모바일 다바이스 아이디
  WEB_ID?: string;  // 웹 브라이드 유니크 아이디 (로컬 스토리지 저장)
  SITE_KEY?: string;
  SAVE_USER_ID?: boolean;
  USE_AUTO_LOGIN?: boolean;
  CHNL_TP?: string;
};

// 사용 언어
export const SERVER_LANGS = ['ko_KR', 'en_US', 'zh_CN'];
export const STR_LANG = { en: 'en', ko: 'ko', zh: 'zh' } as const;
export const NUM_LANG = { en: 1, ko: 0, zh: 2 } as const;
export const languages = [STR_LANG.ko, STR_LANG.en, STR_LANG.zh] as const;
export type Languages = typeof languages[number]; // 'en' | 'ko'
export const LANGS = [
  {
    value: STR_LANG.ko,
    label: '한국어',
    systemValue: koKR,
    icon: '/static/icons/ic_flags_kr.svg',
    ymdFormat: 'yyyy.MM.dd'
  },
  {
    value: STR_LANG.en,
    label: 'English',
    systemValue: enUS,
    icon: '/static/icons/ic_flags_us.svg',
    ymdFormat: 'MM/dd/yyyy'
  },
  {
    value: STR_LANG.zh,
    label: '中国话',
    systemValue: zhCN,
    icon: '/static/icons/ic_flags_cn.svg',
    ymdFormat: 'yy-MM-dd'
  }
];

// 로그인 데이터(필요한 타입만 정의)
export type GDS_LOGIN = {
  USER_OPERSYSM: string;
  LOGIN_ID: string;
  SYST_DSTNCT_CD: string;
  LST_CONN_DT: string;
  ORG_CD?: string | null;
  USER_ID: string;
  IDT_AUTH_CD: string;
  ORG_NM?: string | null;
  IDT_AUTH_NM: string;
  USER_BRWR: string;
  LST_CONN_IP: string;
  CTRF_YN: string;
  SESON_ID: string;
  USER_NM: string;
  ACCSSS_DT: string;
  LOGIN_ERR_MSG: string;
  USER_IP: string;
  LOGIN_ERR_CODE: number;
  AUTH_ID: string;
  AUTH_TARGT_DSTNCT_CD: string;
  AUTH_TARGT_DSTNCT_NM: string;
  AUTH_NM: string;
  MNG_YN: string;
  COMBO_NAME: string;
  IS_REP_ID: string;
  MNG_IP_LIST: string;
};

// 메뉴
export type GDS_MENU = {
  UP_MENU_ID?: string;
  MENU_ID: string;
  MENU_NM: string;
  MENU_ENG_NM?: string;
  MENU_CHN_NM?: string;
  MENU_LVL?: number;
  PGM_PATH: string;
  PARAM: string; // icon
  RMARK?: string;
  TASK_DTL_CD?: string;
  IMG_JSON?: string;
  VIEW_CLS?: string;
  SORT_SEQ?: number;
};

// 1. 페이지 사용 데이터
export type DS_CODE = {
  CD: string;
  CD_GRP_ID?: string;
  KO_NM: string;
  EN_NM?: string;
  ZH_NM?: string;
  SORT_SEQ?: number;
  RMARK?: string;
};

// 첨부파일 정보
export type DS_FILE = {
  FILE_UUID: string; // 파일 저장 키
  FILE_NM: string; // 저장 파일명
  FILE_SIZE: string | undefined;
  FILE_DATA: string | undefined; // 저장된 바이너리 데이터(blob base64)
  FLAG: string;
};

// 학생 기본 정보 hhj_0002_v01.s01
export type DS_STUD = {
  SNO: string;
  NAME: string;
  GRDE: string;
  SCRG_STTUS: string;
  DAYNIG_DVCD: string;
  SCH_CLA_CD: string;
  UNIV_CD: string;
  UNIV_NM: string;
  DPMJ_CD: string;
  DPMJ_NM: string;
  MAJR_CD: string;
  MAJR_NM: string;
  HNPH_NO: string;
  DWLD_URL: string;
  REGI_REG_NO: string;
  EXMT_NO: string;
  GEND_DVCD: string;
  ORG_CD: string;
  ORG_NM: string;
};

// 현재 년도 학기
export type DS_YEARSMR = {
  YY: string;
  SMR: string;
};

// 현재 서버 시간
export type DS_SVR_TIME = {
  D_DT: number; // 숫자형
  S_DT: string; // YYYYMMDDHHMISS
  S_YMD: string; // 년원일
};

