// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
// ----------------------------------------------------------------------

type RHFTextFieldProps = TextFieldProps & {
  name: string;
}
/*
sx={{
            '& input': {
              padding: '13.5px 14px'
            }
          }}
*/
export default function RHFTextField({ name, sx, ...other }: RHFTextFieldProps ) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          variant="outlined"
          {...other}
          sx={sx}
        />
      )}
    />
  );
}
