// material
import { Button, ButtonProps } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Iconify from 'components/Iconify';

import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------
// 프로그램명 : 공통 버튼 관리 컴포넌트
//
// ----------------------------------------------------------------------
interface SZButtonProps extends ButtonProps {
  mtype:
    | 'save'
    | 'del'
    | 'new'
    | 'search'
    | 'gridadd'
    | 'griddel'
    | 'gridsave'
    | 'listdtl'
    | 'listdel'
    | 'init';
  showIcon?: boolean;
}

const NewButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.darker
  }
}));
export default function SZButton({
  type = 'button',
  mtype = 'save',
  children,
  color = 'primary',
  size,
  ref,
  showIcon = false,
  startIcon,
  ...others
}: SZButtonProps) {
  const { t } = useLocales();
  if (!children) {
    children = t(mtype.toUpperCase());
  }

  if (mtype === 'new') {
    return (
      <NewButton size={size} type={type} variant="contained" {...others} ref={ref}>
        {showIcon && (startIcon || <Iconify icon={'eva:add-outlined'} sx={{ mr: 0.5 }} />)}
        {children}
      </NewButton>
    );
  }
  // 초기화 버튼
  if (mtype === 'init') {
    return (
      <Button
        variant="outlined"
        size={size}
        {...others}
        ref={ref}
        startIcon={
          showIcon && <Iconify icon={'eva:autorenew'} sx={{ transform: 'rotate(45deg)' }} />
        }
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      size={size}
      type={type}
      ref={ref}
      variant={'contained'}
      color={
        // new primary
        mtype.endsWith('del') ? 'inherit' : color
      }
      {...others}
    >
      {showIcon && (startIcon || <Iconify icon={'eva:add-outlined'} sx={{ mr: 0.5 }} />)}
      {children}
    </Button>
  );
}
