import { Theme, alpha } from '@mui/material/styles';
//
import { ColorSchema } from '../palette';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  const style = (color: ColorSchema) => ({
    props: { color },
    style: {
      '&.Mui-selected': {
        borderColor: alpha(theme.palette[color].main, 0.48)
      }
    }
  });

  return {
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'standard' },
          style: {
            '&.Mui-selected': {
              backgroundColor: '#D7F2F1'
            }
          }
        },
        style('primary'),
        style('secondary'),
        style('info'),
        style('success'),
        style('warning'),
        style('error')
      ]
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          backgroundColor: theme.palette.background.paper,
          border: '0',
          '& .MuiToggleButton-root': {
            width: '30px',
            height: '30px',
            margin: 0,
            borderColor: 'transparent !important',
            borderRadius: '50% !important'
          }
        }
      }
    }
  };
}
