import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

// eslint-disable-next-line func-names
yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsZero', function () {
  return this.transform((value, originalValue) => (String(originalValue)?.trim() ? value : 0));
});

// 전화번호 포맷
export function isPhoneNumber(this: yup.StringSchema, msg: string) {
  return this.test({
    name: 'isPhoneNumber',
    message: msg || 'isPhoneNumber',
    test: (value) => {
      if (value && !value.match(/^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/g)) {
        return false;
      }
      return true;
    }
  });
}

yup.addMethod<yup.StringSchema>(yup.string, 'isPhoneNumber', isPhoneNumber);
// eslint-disable-next-line func-names
yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsSpace', function () {
  return this.transform((value) => value || '');
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isPhoneNumber(msg?: string): StringSchema<TType, TContext>;
    emptyAsSpace(): StringSchema<TType, TContext>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsZero(): NumberSchema<TType, TContext>;
  }
}

export default yup;
