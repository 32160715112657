import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  if (isLight){
    const logo = (
      <Box sx={{ ...sx }}>
        <img
          src="/logo/logo.png"
          alt="조선이공대학교"
          style={{ width: "100%", maxWidth: "280px" }}
        />
      </Box>
    );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/main">{logo}</RouterLink>;
  }else {
    const logo = (
      <Box sx={{ ...sx }}>
        <img
          src="/logo/logo_d.png"
          alt="조선이공대학교"
          style={{ width: "100%", maxWidth: "280px" }}
        />
      </Box>
    );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/main">{logo}</RouterLink>;
  }
  

}
