import { useTranslation } from 'react-i18next';
import { useSnackbar, VariantType } from 'notistack';
import useLocales from 'hooks/useLocales';
import { alert, confirm } from 'redux/slices/alertDialogState';
// ----------------------------------------------------------------------
export default function useMessages() {
  const { t } = useTranslation();
  const { currentLang } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const show = (message: string, variant: VariantType = 'info') => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration: 1000 // 알림창 1초후 사라짐 설정
    });
  };
  const info = (msgCode: string, ...other: string[]) => {
    msg(msgCode, 'info', ...other);
  };

  const error = (msgCode: string, ...other: string[]) => {
    msg(msgCode, 'error', ...other);
  };

  const warning = (msgCode: string, ...other: string[]) => {
    msg(msgCode, 'warning', ...other);
  };

  const msg = (msgCode: string, type: 'info' | 'error' | 'warning', ...other: string[]) => {
    const m = getMsg(msgCode, ...other);
    show(m, type);
  };

  const getMsg = (msgCode: string, ...other: string[]): string => {
    let rtnMsg = t(msgCode);
    if (other && other.length > 0) {
      other.map((str, index) => (rtnMsg = rtnMsg.replace(`{${index}}`, other[index])));
    }

    return rtnMsg;
  };

  return {
    info,
    error,
    warning,
    show,
    getMsg,
    alert,
    confirm
  };
}
