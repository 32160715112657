// @mui
import { styled, useTheme } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3.5),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(1.5),
  fontSize: "13px",
  color: theme.palette.primary.main,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  const theme = useTheme();
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List
          key={group.subheader}
          disablePadding
          sx={{
            paddingBottom: '15px',
            borderBottom: `1px solid ${theme.palette.background.divider}`
          }}
        >
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0
              })
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot key={`${list.menuId}_${list.title}`} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
