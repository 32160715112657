import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
// components
import { NavSectionHorizontal } from 'components/nav-section';
import { useSelector } from 'redux/store';
import { CommMenuState } from 'redux/slices/commMenuState';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: '52px',
  left: '0',
  width: '100%',
  height: '44px',
  overflow: 'auto',
  flexDirection: 'row',
  background: theme.palette.primary.main,
  zIndex: 10
}));
// ----------------------------------------------------------------------
function NavbarHorizontal() {
  const { gdsTreeMenu: menuList } = useSelector(
    (state: { CommMenuState: CommMenuState }) => state.CommMenuState
  );
  if (!menuList) {
    return <></>;
  }
  return (

    <RootStyle>
      <NavSectionHorizontal navConfig={menuList} />
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
