import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'redux/store';
import { AlertDialogState } from 'redux/slices/alertDialogState';

export default function AlertDialog() {
  const [open, setOpen] = useState(false);
  // 폼 사용 데이터
  const { title, message, onConfirm, yesLabel, noLabel, onCancel, type, cnt } = useSelector(
    (state: { AlertDialogState: AlertDialogState }) => state.AlertDialogState
  );
  // 닫기 취소
  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
    const interval = setInterval(() => {
      if (onCancel) {
        onCancel();
      }
      clearInterval(interval);
    }, 500);
  };

  useEffect(() => {
    if (message && message !== '') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message, cnt, setOpen]);
  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>}
      <DialogContent dividers sx={{ minHeight: '150px' }}>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {type === 'confirm' && (
          <Button
            onClick={() => {
              setOpen(false);
              const interval = setInterval(() => {
                if (onCancel) {
                  onCancel();
                }
                clearInterval(interval);
              }, 500);
            }}
            color="inherit"
            variant="contained"
          >
            {noLabel || '취소'}
          </Button>
        )}
        <Button
          onClick={() => {
            setOpen(false);
            const interval = setInterval(() => {
              if (onConfirm) {
                onConfirm();
              }
              clearInterval(interval);
            }, 500);
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          {yesLabel || '확인'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
