import { ReactNode } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Button, Popover, ButtonProps, LinkProps } from '@mui/material';
// config
import { NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

type IProps = LinkProps & ButtonProps;

interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  open?: boolean;
}

export const ListItemStyle = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'activeRoot' && prop !== 'activeSub' && prop !== 'subItem' && prop !== 'open',
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, open, theme }) => {
  const isLight = theme.palette.mode === 'light';

  const activeRootStyle = {
    color: isLight ? theme.palette.grey[0] : theme.palette.grey[0],
    fontSize: '16px',
    fontWeight: 700
  };

  return {
    ...theme.typography.body1,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0, 1),
    color: 'rgba(255,255,255,.7)',
    height: '100%',

    // activeRoot
    ...(activeRoot && {
      ...theme.typography.subtitle2,
      ...activeRootStyle,
      '&:hover': { ...activeRootStyle }
    }),
    // activeSub
    ...(activeSub && {
      ...theme.typography.subtitle2
    }),
    // subItem
    ...(subItem && {
      width: '100%',
      margin: 0,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
      justifyContent: 'space-between'
    }),
    // open
    ...(open &&
      !activeRoot && {
        backgroundColor: theme.palette.action.hover
      })
  };
});

// ----------------------------------------------------------------------

export const PaperStyle = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    width: 160,
    pointerEvents: 'auto',
    padding: theme.spacing(1),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.text.grey
  }
}));
