import { SettingsValueProps } from './components/settings/type';
// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

// 서버 연결 주소
// dev : 개발서버, local : 로컬, prod: 운영 서버, localdev : http://203.255.28.243:9000
// 설정 : 프로젝트 root 아래 .env 파일 생성 후 REACT_APP_NODE_ENV=local
const NODE_ENV = {
  dev: 'http://devapps.gnu.ac.kr:1202',
  prod: 'https://m.cst.ac.kr',
  local: 'http://localhost:8080',
  localdev: 'http://192.168.0.187:8080'
};
//export const nodeEnv = 'prod';  //서버반영 시 사용
export const nodeEnv = process.env.REACT_APP_NODE_ENV || 'prod'; // 개발에서 사용
export const SERVICE_URL = (NODE_ENV as any)[nodeEnv];
// 서버 호출 서비스명
export const serviceNames = {
  domain: `${SERVICE_URL}/pnsmobsvc/my%23comm/domain`,
  main: '/mysvc/my%23jdefault', // 기본서비스
  login: '/mysvc/my%23login', // 로그인
  idcard: '/mysvc/my%23idcard', // 모바일 신분증
  logout: '/mysvc/my%23login/logout', // 로그아웃
  checkSession: '/mysvc/my%23login/check', // 세선 정보 확인
  pushMsg: '/pnsapi/pns%23message/write', // 푸시 메시지 발행
  fileDownloadUrl: (path: string | undefined) => {
    if (path) {
      return `${SERVICE_URL}/pns/files/${path}`;
    }
    return undefined;
  }
};

// 홈페이지 연동
export const HP_BIZ_NAME = 'my#myHomepageBiz';
// 오라클 : 조직정보
export const MY_HP_BIZ_NAME = 'my#myHomeBiz';
// 공지사항 루트 경로
export const HP_NOTICE_FILE_ROOT = 'https://www.cst.ac.kr';
// 비밀번호 찾기 경로
export const PWD_FIND_URL = 'https://www.cst.ac.kr/member/find?site=basic&mn=1319&type=find_window';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.main; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 52,
  MAIN_DESKTOP_HEIGHT: 52,
  DASHBOARD_DESKTOP_HEIGHT: 52,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
};

export const NAVBAR = {
  BASE_WIDTH: 260, // 260,
  DASHBOARD_WIDTH: 0, // 260,
  DASHBOARD_COLLAPSE_WIDTH: 0, // 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------
// 중복되면 안됨.
export const settingsSaveKey = 'cst_settings';
export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false
};

// 사용자 아이디 저장 키
export const STORE_USER_ID_KEY = 'cst_my_id';
// 웹 아이디 저장 키
export const STORE_WEB_ID_KEY = 'cst_web_id';
