import { ReactNode, useEffect } from 'react';
import { isString } from 'lodash';
import { useDropzone, DropzoneOptions, FileRejection, DropEvent } from 'react-dropzone';
import Image from 'components/Image';
import Iconify from 'components/Iconify';
import { m, AnimatePresence } from 'framer-motion';
// material
import { alpha, Theme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
import { SxProps } from '@mui/system';
import { fData } from 'utils/formatNumber';
import { varFade } from 'components/animate';
import useAuth from 'hooks/useAuth';
import { DS_FILE } from '_szcom/types/globaltypes';
import RejectionFiles from 'components/upload/RejectionFiles';
// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  height: 70,
  width: '100%',
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
  // [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------
export interface CustomFile extends File {
  path?: string;
  preview?: string;
  FILE_NM?: string;
  FLAG?: string;
  FILE_DATA?: string;
}

export interface UploadFileProps extends DropzoneOptions {
  error?: boolean;
  helperText?: ReactNode;
  files: (File | string | DS_FILE)[]; // 현재 파일 목록
  showPreview?: boolean;
  multiple?: boolean;
  onRemove?: (file: CustomFile | string | DS_FILE) => void;
  onChangeFiles?: (file: (CustomFile | string | DS_FILE)[]) => void;
  onRemoveAll?: VoidFunction;
  sx?: SxProps<Theme>;
  fileMode?: 'download' | 'upload'; // 파일모드
}

const getFileData = (file: CustomFile | string | DS_FILE) => {
  const f = file as any;
  if (f.FILE_UUID) {
    const f = file as DS_FILE;
    return {
      key: f.FILE_UUID,
      name: f.FILE_NM,
      size: f.FILE_SIZE,
      FILE_DATA: f.FILE_DATA,
      FLAG: f.FLAG
    };
  }
  if (typeof file === 'string') {
    return {
      key: file,
      FLAG: 'I'
    };
  }
  return {
    key: f.name,
    name: f.name,
    size: f.size,
    preview: f.preview,
    FILE_DATA: f.FILE_DATA,
    FLAG: 'I'
  };
};

export default function UploadFile({
  error,
  showPreview = false,
  multiple = true,
  files = [],
  onRemove,
  onChangeFiles,
  onRemoveAll,
  sx,
  fileMode = 'upload',
  ...other
}: UploadFileProps) {
  console.log(files);
  // 삭제 파일 제외하고, 파일 존재하는지 확인
  const delFileCnt =
    (files && files.filter((_f: any) => _f.FILE_UUID !== undefined && _f.FLAG === 'D').length) || 0;
  const hasFile = files && files.length > 0 && files.length - delFileCnt > 0;
  // 신규 추가된 파일
  const newFileCnt = (files && files.filter((_f: any) => _f.FILE_UUID === undefined).length) || 0;

  // 파일 선택 시 처리
  const handleDrop =  <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
    const newfiles = acceptedFiles.map((file: Blob | MediaSource) => {
      const f = Object.assign(file, {
        preview: URL.createObjectURL(file),
        FLAG: 'I',
      }) as CustomFile;
      getBase64(f);
      return f;
    });
    const newList = multiple ? files.concat(newfiles as CustomFile[]) : newfiles;
    console.log(newList);
    if (onChangeFiles && multiple) {
      onChangeFiles(newList);
    }
  };

   // 문자열로 데이터 가져오기
   const getBase64 = (file: CustomFile, callback?: (data: any) => VoidFunction) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const filedata = (reader.result as string).split(',');
      file.FILE_DATA = filedata[1];
      file.FILE_NM = file.path;
      // 단건인 경우
      if (onChangeFiles && !multiple) {
        onChangeFiles([file]);
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };
  // 파일 핸들링
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    onDrop: handleDrop,
    ...other
  });

  // 파일 삭제
  const handleRemoveFile = (file: string | File | DS_FILE) => {
    if (onRemove) {
      onRemove(file);
      return;
    }
    const f = file as any;
    let newList: (File | DS_FILE | string)[] = [];
    if (f.FILE_UUID) {
      // 업로드된 파일
      newList = files.map((_file) => {
        if (_file !== file) {
          return _file;
        }
        return { ...(_file as DS_FILE), FLAG: 'D' };
      });
    } else {
      // 신규 파일로 그냥 삭제
      newList = files.filter((_file) => _file !== file);
    }
    if (onChangeFiles) {
      onChangeFiles(newList);
    }
  };

  // 파일 전체 삭제 선택
  const handleRemoveAll = () => {
    if (onRemoveAll) {
      onRemoveAll();
      return;
    }
    const newList: (File | DS_FILE | string)[] = files
      .filter((_f: any) => _f.FILE_UUID !== undefined)
      .map((_f: any) => ({ ...(_f as DS_FILE), FLAG: 'D' }));

    if (onChangeFiles) {
      onChangeFiles(newList);
    }
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {(!hasFile || multiple) && fileMode === 'upload' && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter',
            }),
          }}
        >
          <input {...getInputProps()} />

          <Box sx={{ p: 3, ml: { md: 2 } }}>
            <Typography gutterBottom variant="subtitle1">
              {delFileCnt > 0 || newFileCnt > 0
                ? '파일이 변경되어, 저장이 필요합니다.'
                : '파일을 선택하세요.'}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Drop or click
            </Typography>
          </Box>
        </DropZoneStyle>
      )}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />} 
      <List disablePadding sx={{ ...(hasFile && { my: 1 }) }}>
        <AnimatePresence>
          {files.map((file) => {
            const { key, name, size, preview, FLAG, FILE_DATA } = getFileData(file);
            if (FLAG === 'D') {
              return <></>;
            }

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <Image alt="preview" src={isString(file) ? file : preview} ratio="1/1" />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFile(file)}
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify
                  icon={'eva:file-fill'}
                  sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }}
                />

                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? '' : fData(size || 0)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                {fileMode === 'upload' && (
                  <Iconify 
                    onClick={() => handleRemoveFile(file)}
                    icon={'mdi:close'} 
                    sx={{ width: 24, height: 24, color: 'text.secondary' }}
                  />
                )}
                {FILE_DATA && name && ( // (file as any).FILE_UUID !== undefined
                  <a
                    href={FILE_DATA}
                    rel="noreferrer"
                    download={name}
                  >
                    <Iconify
                      icon={'mdi:arrow-collapse-down'}
                      sx={{ width: 24, height: 24, color: 'text.secondary', ml: 1 }}
                    />
                  </a>
                )}
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && multiple && fileMode === 'upload' && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={handleRemoveAll} sx={{ mr: 1.5 }}>
            전체 삭제
          </Button>
        </Stack>
      )}
    </Box>
  );
}
