import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';
// ---------------------------------------------------------------------
const typography = {
  fontFamily: "Noto Sans KR",
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    letterSpacing: 2,
  },
  h2: {
    //페이지 타이틀
    fontWeight: 700,
    lineHeight: "28px",
    fontSize: pxToRem(19),
  },
  h3: {
    //페이지 네비
    fontWeight: 700,
    lineHeight: "19px",
    fontSize: pxToRem(16),
  },
  h4: {
    //서브페이지 네비
    fontWeight: 700,
    lineHeight: "19px",
    fontSize: pxToRem(15),
  },
  h5: {
    //박스 타이틀
    fontWeight: 700,
    lineHeight: "19px",
    fontSize: pxToRem(14),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
  },
  subtitle1: {
    //total fz15-500
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: pxToRem(15),
  },
  subtitle2: {
    //total fz14-500
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body0: {
    //fz20-bold
    fontWeight: 700,
    lineHeight: "22px",
    fontSize: pxToRem(20),
  },
  body1: {
    //fz16
    lineHeight: "20px",
    fontSize: pxToRem(16),
  },
  body1B: {
    //fz16-bold
    fontWeight: 500,
    lineHeight: "20px",
    fontSize: pxToRem(16),
  },
  body2: {
    //fz14
    lineHeight: "16px",
    fontSize: pxToRem(14),
  },
  body2B: {
    //fz14-bold
    fontWeight: 500,
    lineHeight: "16px",
    fontSize: pxToRem(14),
  },
  body3: {
    //fz13
    lineHeight: "16px",
    fontSize: pxToRem(13),
  },
  body3B: {
    //fz13-bold
    fontWeight: 500,
    lineHeight: "16px",
    fontSize: pxToRem(13),
  },
  impoTxt: {
    color: '#00A7A1'
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
} as const;

declare module '@mui/material/styles' {
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties;
    body1B?: React.CSSProperties;
    body2B?: React.CSSProperties;
    body3?: React.CSSProperties;
    body3B?: React.CSSProperties;
    impoTxt?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body0: true;
    body1B: true;
    body2B: true;
    body3: true;
    body3B: true;
    impoTxt: true;
  }
}



export default typography;
