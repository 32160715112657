import { lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import { Loadable } from 'routes';
// pages
import { Alert, AlertTitle, Container, ContainerProps, Stack, Typography } from '@mui/material';
import LoadingData from '_szcom/components/LoadingData';
import Page from 'components/Page';
import { HttpStatusState, clear } from 'redux/slices/httpStatus';
import { useDispatch, useSelector } from 'redux/store';
import { GDS_MENU, STR_LANG } from '_szcom/types/globaltypes';
import useMessages from 'hooks/useMessages';
import useLocales from 'hooks/useLocales';
import { useTheme } from '@mui/material/styles';
// 상태 관리
import { CommMenuState } from 'redux/slices/commMenuState';
import { selectMenu } from 'redux/slices/commCurrentMenuState';
import Page404 from './comm/Page404';
import NavbarHorizontal from 'layouts/dashboard/navbar/NavbarHorizontal';
import { toInteger } from 'lodash';

// ----------------------------------------------------------------------
export interface PageContainerProps {
  title?: string;
  dsCommCode?: any;
  links?: any[];
}
type MyContainterProps = ContainerProps & {
  menu: GDS_MENU | undefined; // 현재 메뉴
};

// 로컬 사용 메뉴
const LOCAL_MENU: GDS_MENU[] = [
  {
    MENU_ID: 'comm/push/COMPUSH',
    MENU_NM: '푸시 알림 메시지',
    MENU_ENG_NM: 'notification messages',
    MENU_CHN_NM: '',
    PGM_PATH: 'comm/push/COMPUSH',
    PARAM: ''
  }
];
// ----------------------------------------------------------------------
// 페이지 컨테이너
// 언어, 색상 등의 설정 변경을 인식하여, 페이지 다시 표시, PageContainer와 별도 처리
// ----------------------------------------------------------------------
function MyContainer({ menu, children }: MyContainterProps) {
  const { isCollapse } = useCollapseDrawer();
  const { themeStretch } = useSettings();
  const { currentLang } = useLocales();
  // redux 사용시 화면 다시 그려지는 현상 있음 -> 상태 변경에 따른 영향
  const dispatch = useDispatch();
  // 현재 선택한 메뉴 정보 설정
  dispatch(selectMenu(menu));

  // 현재 제목
  const title =
    // eslint-disable-next-line no-nested-ternary
    (currentLang.value === STR_LANG.en
      ? menu?.MENU_ENG_NM
      : currentLang.value === STR_LANG.zh
      ? menu?.MENU_CHN_NM
      : menu?.MENU_NM) || '';

  // 축소된 부분에 가려짐.  && themeStretch
  return (
    <Container sx={{ padding: '0 !important' }}>
      <HttpApiMessageDisplay />
      <Page title={title}>{children}</Page>
    </Container>
  );
}

// ----------------------------------------------------------------------
// 메인 페이지
// ----------------------------------------------------------------------
export default function PageContainer() {
  const { pathname } = useLocation();
  const menuId = `${pathname.substr(pathname.lastIndexOf('/') + 1)}`;
  const isMain = pathname.includes('/main');
  const isCom = pathname.includes('/comm/'); // comm인 경우 처리(페이지 직접 이동 지원)
  // 메뉴 정보 가져오기
  const { gdsMenu } = useSelector((state: { CommMenuState: CommMenuState }) => state.CommMenuState);
  let currentMenu = gdsMenu ? gdsMenu.find((_menu) => _menu.MENU_ID === menuId) : undefined;
  if (!isMain && !currentMenu) {
    // isMain = true;
  }
  if (isCom) {
    currentMenu = LOCAL_MENU.find((_menu) => _menu.MENU_ID === pathname.substr(1));
  }
  // eslint-disable-next-line no-nested-ternary
  const pageUrl = isMain ? 'comm/main/MainPage' : currentMenu?.PGM_PATH;
  if (!pageUrl) {
    // alert('메뉴 경로가 존재하지 않습니다.');
    // console.log('## 메뉴 정보 미존재 ##');
    if (gdsMenu && gdsMenu.length > 0) {
      return <Page404 />;
    }
    return <></>;
  }
  const MyPage = Loadable(lazy(() => import(`./${pageUrl}`)));
  return (
    <MyContainer menu={currentMenu} sx={{ paddingTop: '52px' }}>
      {!isMain && <NavbarHorizontal />}
      <MyPage />
    </MyContainer>
  );
}

// 에러 메시지 처리 공통
function HttpApiMessageDisplay() {
  // const { code, message, error, init, loading } = useHttpApiStatus();
  const { code, message, serviceId, success, loading } = useSelector(
    (state: { HttpStatus: HttpStatusState }) => state.HttpStatus
  );
  console.log(`[${serviceId}] ${code}: ${message} ${success}`);
  const { logout } = useAuth();
  const { error } = useMessages();
  const [alertMsg, setAlertMsg] = useState<string | undefined>();
  useEffect(() => {
    if (code === -200) {
      // 세션 없음
      // 로그아웃으로 메인 페이지로 이동
      error('MCOM00003'); // 로그인 정보 없음.
      logout();
      // const interval = setInterval(() => {
      //   clear();
      // }, 500);
      return () => clear() // clearInterval(interval);
    }
    if (success === false && message) {
      // error(message);
      setAlertMsg(message);
      return () => clear();
    }
    return () => {}; // clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, message, success, serviceId]);

  if(alertMsg && alertMsg !== '') {
    return (
      <Stack sx={{ width: '100%', top: 0, position: 'fixed', zIndex: 9999 }} spacing={2}>
        <Alert severity="error" onClose={() => {setAlertMsg(undefined)}}>
          <AlertTitle>시스템 오류</AlertTitle>
          {alertMsg}
        </Alert>
      </Stack>
    );
  }

  //  현재 처리중인 경우 페이지 띄우기
  if (loading) {
    return (
      <LoadingData
        sx={{
          ...{
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed'
          }
        }}
      />
    );
  }

  return <></>;
}
