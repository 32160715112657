// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth'; // 메인 시작 페이지
const ROOTS_MAIN = '/';
const ROOTS_COMM = '/comm'; // 공통 페이지 바로
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_MAIN = {
  root: ROOTS_MAIN,
  main: path(ROOTS_MAIN, 'main'),
  menu: path(ROOTS_MAIN, 'menu')
};

export const PATH_DASHBOARD = {
  root: ROOTS_MAIN,
  main: path(ROOTS_MAIN, '/main'),
  menu: path(ROOTS_MAIN, '/menu/'),
  push: {
    root: path(ROOTS_COMM, '/push/COMPUSH'),
    write: path(ROOTS_COMM, '/push/PUSH_P01') // 푸시 메시지 작성
  },
  user: {
    root: path(ROOTS_MAIN, '/user')
  }
};
