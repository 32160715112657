// @mui
import { Button, ButtonProps } from "@mui/material";

// ----------------------------------------------------------------------
// 프로그램명 : Pre태그 텍스트 박스
// ----------------------------------------------------------------------
export default function PreTxt({
  children,
  fullWidth,
  variant,
  type,
  sx,
  onClick,
  ...other
}: ButtonProps) {
  return (
    <pre
      style={{
        width: "100%",
        fontSize: "15px",
        lineHeight: "22px",
        whiteSpace: "pre-line",
      }}
    >
      {children}
    </pre>
  );
}
