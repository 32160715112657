import { merge, toInteger } from 'lodash';
import { AuthUser } from '_szcom/types/globaltypes';
import { DataRowType, DataTypes } from '_szcom/types/datasets';
import { serviceNames } from 'config';
import axios, { HttpApiErrorType } from 'utils/axios';
import { start, end } from 'redux/slices/httpStatus';
import { getCurrentMenu } from '_szcom/utils/global';
// ----------------------------------------------------------------------
export interface KeyValueType {
  [key: string]: number | string;
}
export interface Datasets {
  [key: string]: Dataset;
}
// 트랜잭션 처리
enum TxTypes {
  normal = 'N', // 기본
  save = 'S', // 등록,수정,삭제
  multi = 'M', // 여러건
  csv = 'CSV'
}
// 실행 타입
enum ExecTypes {
  normal = 'N', // 기본
  batch = 'B', // 배치
}

export interface Dataset {
  name?: string; // 이름
  positon?: number; // 위치
  comment?: string;
  columns?: KeyValueType;
  data: any[];
}

export type FSP_CMD = {
  TYPE?: string;
  SQL_ID: string;
  KEY_SQL_ID?: string;
  KEY_INCREMENT?: number;
  CALLBACK_SQL_ID?: string;
  INSERT_SQL_ID?: string;
  UPDATE_SQL_ID?: string;
  DELETE_SQL_ID?: string;
  SAVE_FLAG_COLUMN?: string;
  KEY_ZERO_LEN?: number;
  EXEC_TYPE?: string;
  USE_INPUT?: string;
  USE_ORDER?: string;
  BIZ_NAME?: string;
  READ_ALL?: string;
  PAGE_NO?: number;
  PAGE_SIZE?: number;
}

export type FspCommandType = {
  fsp_userInfo?: {
    LOGIN_ID: string;
    USER_ID: string;
    USER_NM: string;
  };
  fsp_pgmInfo?: {
    PGM_PATH: string;
    MENU_ID: string;
    TASK_DTL_CD: string;
  };
  fsp_action: string;
  fsp_cmd: string;
  fsp_ds_cmd: undefined | FSP_CMD[];
  bizName: string;
};

interface FspCommandRequest {
  clear(): void;
  add(row: FSP_CMD): void;
  addSearch(sqlmap: string, isUseInput?: boolean, isNotUseOrder?: boolean, rtnDsId?: string): void;
  addPageSearch(sqlmap: string, pageNo: number, pageSize: number, isAllRead?: boolean, isUseInput?: boolean, isNotUseOrder?: boolean, rtnDsId?: string): void;
  addSingle(sqlmap: string,
    keySqlName?: string,
		keyIncrement?: number,
		callbackSql?: string,
		execType?: ExecTypes): void;
  addMulti(sqlmap: string,
    keySqlName?: string,
    keyIncrement?: number,
    callbackSql?: string,
    execType?: ExecTypes): void;
  addSave(insertSql?: string,
    updateSql?: string,
    deleteSql?: string,
    saveFlagColumn?: string,
    keySqlName?: string,
		keyIncrement?: number,
		callbackSql?: string,
    keyZeroLen?: number,
	  execType?: ExecTypes,
    ): void;
  setUserInfo(user: AuthUser): void;
  requestData: FspCommandType;
  setBizName(name: string): void;
}

export function defaultFsp(): FspCommandType {
  const menu = getCurrentMenu();
  const requestData: FspCommandType = {
    fsp_userInfo: {
      LOGIN_ID: '',
      USER_ID: '',
      USER_NM: ''
    },
    fsp_pgmInfo: {
      PGM_PATH: menu ? menu.PGM_PATH : '',
      MENU_ID: menu ? menu.MENU_ID : '',
      TASK_DTL_CD: menu ? menu.TASK_DTL_CD || '' : ''
    },
    fsp_ds_cmd: [],
    fsp_action: '',
    fsp_cmd: 'execute',
    bizName : ''
  };
  return requestData;
}

// 임력 데이터 추가
export function addInputData(
  data: any,
  variable: any | undefined,
  dataset: Datasets | undefined
) {
  if (variable) {
    // eslint-disable-next-line array-callback-return
    Object.keys(variable).some((key) => {
      data[key] = variable[key];
    });
  }

  if (dataset) {
    // eslint-disable-next-line array-callback-return
    Object.keys(dataset).forEach((key, index) => {
      const val = dataset[key];
      if (!Array.isArray(val.data)) {
        val.data = [val.data];
      }
      /*
      if (!val.positon || val.positon < 0) {
        val.positon = 0;
      }
      if (!val.columns && val.data.length > 0) {
        val.columns = getColumns(val.data[0]);
      }
      */
      data[key || val.name || `ds_in${index}`] = val.data;
    });
  }
}

export function parseRespons(resData: any): {
  dataset: Datasets;
  variable: any;
  code: number;
  message: string;
} {
  let dataset: Datasets = {};
  let variable: any = {};

  Object.keys(resData).forEach((key) => {
    const val = resData[key];
    if (Array.isArray(val) || typeof val === 'object') {
      dataset[key] = convertDataset(key, val);
    } else {
      variable[key] = val;
    }
  });
  const { ErrorCode, ErrorMsg: message } = resData;
  /*
  let nextDs: Dataset[] = [];
  if (dataset && Array.isArray(dataset)) {
    nextDs = dataset.map(
      (ds, index) =>
        ({
          data: ds.data.map((row: any) => ({ ...row, _rowState: DataRowType.ROW_TYPE_NORMAL })),
          name: `ds_out${index}`,
          columns: { ...ds.columns, _rowState: DataTypes.string }
        } as Dataset)
    );
  }
  */
  return { dataset, variable, code: toInteger(ErrorCode), message };
}

// 전송해야하는 구조체를 생성한다.
export function convertDataset(name: string = 'ds_in', ds: any): Dataset {
  // eslint-disable-next-line no-nested-ternary
  const obj = (Array.isArray(ds) ? (ds.length > 0 ? ds[0] : {}) : ds) as any;
  return {
    data: Array.isArray(ds) ? ds : [ds],
    name,
    positon: 0,
    columns: getColumns(obj)
  };
}

// 데이터셋 컬럼을 생성한다.
export function getColumns(obj: any) {
  const tmp = {} as any;
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    const dataType = DataTypes[typeof val];
    tmp[key] = dataType;
  });

  return tmp;
}

export const createFsp = (): FspCommandRequest => fsp();

const fsp = (): FspCommandRequest => {
  const requestData = defaultFsp();
  const clear = () => {
    requestData.fsp_ds_cmd = [];
    requestData.bizName = '';
  };

  const add = ({
    TYPE = TxTypes.normal.toString(),
    EXEC_TYPE = ExecTypes.normal.toString(),
    SQL_ID = '',
    KEY_SQL_ID = '',
    KEY_INCREMENT = 0,
    KEY_ZERO_LEN = 0,
    CALLBACK_SQL_ID = '',
    INSERT_SQL_ID = '',
    UPDATE_SQL_ID = '',
    DELETE_SQL_ID = '',
    SAVE_FLAG_COLUMN = '',
    USE_INPUT = 'N',
    USE_ORDER = 'Y',
    BIZ_NAME = requestData.bizName,
    READ_ALL = 'N',
    PAGE_NO = 0,
    PAGE_SIZE = 0
  }: FSP_CMD) => {
      requestData.fsp_ds_cmd?.push({
        TYPE,
        EXEC_TYPE,
        SQL_ID,
        KEY_SQL_ID,
        KEY_INCREMENT,
        KEY_ZERO_LEN,
        CALLBACK_SQL_ID,
        INSERT_SQL_ID,
        UPDATE_SQL_ID,
        DELETE_SQL_ID,
        SAVE_FLAG_COLUMN,
        USE_ORDER,
        USE_INPUT,
        BIZ_NAME,
        READ_ALL,
        PAGE_SIZE,
        PAGE_NO
      });
    };

  const addSearch = (sqlmap: string, isUseInput?: boolean, isNotUseOrder?: boolean, rtnDsId?: string): void => {
    add({
      SQL_ID: sqlmap,
      SAVE_FLAG_COLUMN: rtnDsId,
      USE_INPUT: isUseInput ? 'Y' : 'N',
      USE_ORDER: isNotUseOrder ? 'Y' : 'N'
    });
  };
  const addPageSearch = (sqlmap: string, pageNo: number, pageSize: number, isAllRead?: boolean, isUseInput?: boolean, isNotUseOrder?: boolean, rtnDsId?: string): void => {
    add({
      SQL_ID: sqlmap,
      PAGE_NO: pageNo,
      PAGE_SIZE: pageSize,
      READ_ALL: isAllRead === true ? 'Y' : 'N',
      SAVE_FLAG_COLUMN: rtnDsId,
      USE_INPUT: isUseInput ? 'Y' : 'N',
      USE_ORDER: isNotUseOrder ? 'Y' : 'N'
    });
  }

  const addSingle = (sqlmap: string,
    keySqlName?: string,
		keyIncrement?: number,
		callbackSql?: string,
		execType?: ExecTypes): void => {
      add({
        SQL_ID: sqlmap,
        KEY_SQL_ID: keySqlName,
        KEY_INCREMENT: keyIncrement,
        CALLBACK_SQL_ID: callbackSql,
        EXEC_TYPE: execType ? execType.toString() : ExecTypes.normal.toString()
      });
  };

  const addMulti = (sqlmap: string,
    keySqlName?: string,
		keyIncrement?: number,
		callbackSql?: string,
		execType?: ExecTypes): void => {
    add({
      TYPE: TxTypes.multi.toString(),
      SQL_ID: sqlmap,
      KEY_SQL_ID: keySqlName,
      KEY_INCREMENT: keyIncrement,
      CALLBACK_SQL_ID: callbackSql,
      EXEC_TYPE: execType ? execType.toString() : ExecTypes.normal.toString()
    });
  };

  const addSave = (
    insertSql?: string,
    updateSql?: string,
    deleteSql?: string,
    saveFlagColumn?: string,
    keySqlName?: string,
		keyIncrement?: number,
		callbackSql?: string,
    keyZeroLen?: number,
	  execType?: ExecTypes
  ): void => {
    add({
      TYPE: TxTypes.save.toString(),
      SQL_ID: '',
      INSERT_SQL_ID: insertSql,
      UPDATE_SQL_ID: updateSql,
      DELETE_SQL_ID: deleteSql,
      KEY_SQL_ID: keySqlName,
      KEY_INCREMENT: keyIncrement,
      KEY_ZERO_LEN: keyZeroLen,
      CALLBACK_SQL_ID: callbackSql,
      EXEC_TYPE: execType ? execType.toString() : ExecTypes.normal.toString()
    });
  };

  const setUserInfo = (user: AuthUser): void => {
    if (user && requestData.fsp_userInfo) {
      requestData.fsp_userInfo.LOGIN_ID = user.id;
      requestData.fsp_userInfo.USER_ID = user.id;
      requestData.fsp_userInfo.USER_NM = user.displayName;
    }
  };

  const setBizName = (name: string): void => {
    requestData.bizName = name;
  };

  const response: FspCommandRequest = {
    addSingle,
    addSearch,
    addMulti,
    clear,
    addSave,
    setUserInfo,
    add,
    addPageSearch,
    requestData,
    setBizName
  };

  return response;
};

// api 생성
type UseHttpApiArgs = {
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'OPTIONS';
  url?: string;
  serviceId?: string;
  action?: string; // action명
  cmd?: string; // command명
  sqlmap?: FspCommandType;
  variable?: any;
  dataset?: Datasets;
  user?: AuthUser;
  showMsg?: boolean;
  // callback?: (result: UseHttpApiCallbackType) => void;
};
// 처리 결과 타입
export type UseHttpApiCallbackType = {
  success: boolean;
  serviceId: string;
  error?: HttpApiErrorType;
  data: any; // 전체 데이터
  variable: any; // 파라메터
  dataset: Datasets; // 데이터 셋
  status: number;
  code: number;
  message: string;
};

export const callService = async ({
  method = 'POST',
  url = serviceNames.main,
  serviceId = 'search',
  action = 'my#jdefault',
  cmd = 'execute',
  sqlmap = defaultFsp(),
  variable = {},
  dataset,
  user,
  showMsg = true
}: UseHttpApiArgs) => {
  sqlmap.fsp_action = action;
  sqlmap.fsp_cmd = cmd;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const axiosConfig = {
    // ...globalConfig,
    method,
    url,
    data: merge({}, sqlmap)
  };
  const { data } = axiosConfig;
  addInputData(data, variable, dataset);

  // setLoading(true);
  if (showMsg) {
    start(serviceId);
  }
  let result: UseHttpApiCallbackType = {
    serviceId,
    success: true,
    data: {},
    status: 0,
    code: 0,
    message: '',
    dataset: {},
    variable: {}
  };
  try {
    if (user && sqlmap.fsp_userInfo) {
      sqlmap.fsp_userInfo.LOGIN_ID = user.USER_ID;
      sqlmap.fsp_userInfo.USER_ID = user.USER_ID;
      sqlmap.fsp_userInfo.USER_NM = user.USER_NM;
    }
    const response = await axios.post(axiosConfig.url, axiosConfig.data);
    const { status } = response;
    const rtn = parseRespons(response.data);
    result = {
      serviceId,
      success: status === 200 && rtn.code === 0,
      data: response.data,
      status,
      code: rtn.code,
      message: rtn.message,
      dataset: rtn.dataset,
      variable: rtn.variable
    };
    if (showMsg) {
      end(result);
    }
    return result;
  } catch (error) {
    const e = error as HttpApiErrorType;
    // setError(e);
    const { code, message, responseData, status } = e;
    result = { ...result, code, message, status, success: false, data: responseData };
    // if (showMsg) {
    end(result); // error);
    // }
    return result;
  }
}; // callservice

export default {
  createFsp,
  fsp,
  parseRespons,
  convertDataset,
  addInputData,
  callService
} as const;
