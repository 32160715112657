import {
  Box,
  DialogActions,
} from "@mui/material";
// components
import SZButton from "_szcom/components/SZButton";
import PreTxt from "_szcom/components/PreTxt";
import PopupWrap from "_szcom/popup/PopupWrap";
import PopupHeader from "_szcom/popup/PopupHeader";
import PopupMain from "_szcom/popup/PopupMain";
// ----------------------------------------------------------------------
// 개인정보 동의 창 표시 (CST0002)
// ----------------------------------------------------------------------

// 개인정보 제공 동의
export type CST0002Props = {
  onClose: (agree: boolean) => void;
};

export default function CST0002({ onClose } : CST0002Props) {
  // 동의 여부
  const handleAgree = (agree: boolean) => {
    // 개인정보 동의
    onClose(agree);
  };

  return (
    <PopupWrap>
      <PopupHeader>개인정보제공동의</PopupHeader>
      <PopupMain mtype="useBtn">
        <Box p={2.5}>
          <PreTxt>
            조선이공대학교가 취급하는 모든 개인정보는 관련법령에 근거하거나
            정보주체의 동의에 의하여 수집‧ 보유 및 처리되고 있습니다.
            <br />
            「개인정보보호법」은 이러한 개인정보의 취급에 대한 일반적 규범을
            제시하고 있으며, 조선이공대학교는 이러한 법령의 규정에 따라
            수집‧보유 및 처리하는 개인정보를 공공업무의 적절한 수행과
            조선이공대학교 구성원의 권익을 보호하기 위해 적법하고 적정하게
            취급할 것입니다. <br /><br />또한, 조선이공대학교는 관련 법령에서 규정한 바에
            따라 우리 대학교에서 보유하고 있는 개인정보에 대한 열람청구권 및
            정정청구권 등 여러분의 권익을 존중하며, 여러분은 이러한 명령상
            권익의 침해 등에 대하여 행정심판법에서 정하는 바에 따라 행정심판을
            청구할 수 있습니다. 다음은 조선이공대학교의 개인정보처리방침을 설명
            드리겠습니다. 이는 현행 「개인정보보호법」제30조 제1항 동법 시행령
            제31조 제1항의 규정에 근거를 두고 있습니다.
          </PreTxt>
        </Box>
        <DialogActions>
          <SZButton mtype="del" onClick={(e) => handleAgree(false)}>미동의</SZButton>
          <SZButton mtype="save" onClick={(e) => handleAgree(true)}>동의</SZButton>
        </DialogActions>
      </PopupMain>
    </PopupWrap>
  );
}
