import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          // margin: '0',
          boxShadow: theme.customShadows.z24,
          '&.MuiPaper-rounded': {
            borderRadius: Number(theme.shape.borderRadius) * 2
          },
          '&.MuiDialog-paperFullScreen': {
            borderRadius: 0
          },
          '@media (max-width: 663.95px)': {
            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
              maxWidth: '100%'
            }
          }
        },
        paperFullWidth: {
          width: '100%',
          borderRadius: '0'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          borderTop: 0,
          borderBottom: 0,
          padding: theme.spacing(3)
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          padding: '0 !important',
          '& > button': {
            width: '100%',
            height: '54px',
            margin: '0 !important',
            borderRadius: '0 !important'
          }
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.dark
        }
      }
    }
  };
}
