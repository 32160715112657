// material
import { Typography, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import useLocales from 'hooks/useLocales';
import { fCurrency } from 'utils/formatNumber'
// ----------------------------------------------------------------------
// 프로그램명 : 그리드 표시 영역 백그라우드
// ----------------------------------------------------------------------
interface SelectResultTitleProps extends StackProps {
  title?: string;
  count: number;
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined;
}

export const SubInfoTxt = styled('span')(({ theme }) => ({
  paddingLeft: '5px',
  fontWeight: 700,
  '& strong': {
    color: theme.palette.primary.dark
  }
}));

export default function SelectResultTitle({
  count = 0,
  title = '',
  sx,
  pb = 1,
  variant = 'subtitle2',
  ...other
}: SelectResultTitleProps) {
  // const theme = useTheme();
  // const isLight = theme.palette.mode === 'light';
  const { t } = useLocales();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={sx} pb={pb}>
      <Typography variant={variant}>
        {title}
        {count >= 0 && (
          <>
            <SubInfoTxt>
              <strong>{fCurrency(count)}</strong>
            </SubInfoTxt>
            {t('items', '건')}
          </>
        )}
      </Typography>
    </Stack>
  );
}
