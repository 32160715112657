import { noCase } from 'change-case';
import { useState, useCallback, useRef, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

// components
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/animate';
import { useDispatch, useSelector } from 'redux/store';
import { CommPushState, useCommPush } from 'redux/slices/commPushState';
import { SelectResultTitle } from '_szcom/components';
import { DS_PUSH } from '_szcom/types/push';
import * as dataUtils from '_szcom/utils/dataUtils';

// ----------------------------------------------------------------------
export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // 뱃지 설정
  const [badgeCount, setBadgeCount] = useState<number>(0);
  const dispatch = useDispatch();
  const { searchUnRead, initPushData } = useCommPush();
  const { dsUnReadList } = useSelector(
    (state: { CommPushState: CommPushState }) => state.CommPushState
  );
  // 앱 뱃지 호출
  const appDeviceBadge = (count: number) => {
    window.fspmob({ api: 'notification', cmd: 'setBadgeCount', param: [count] });
  };
  // 푸시 건수 변경
  useEffect(() => {
    if (dsUnReadList) {
      setBadgeCount(dsUnReadList.length);
      appDeviceBadge(dsUnReadList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsUnReadList]);

  // 화면 Open
  const handleOpen = async () => {
    await dispatch(searchUnRead());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 상세 페이지로 이동
  const handleDetail = useCallback(
    (msgId: string) => {
      handleClose();
      localStorage.setItem('fspMsgId', msgId);
      return navigate(`/menu/MM06/MM0602`);
    },
    [navigate]
  );

  useEffect(() => {
    // 미확인 메시지 최초 조회
    dispatch(searchUnRead());
    return () => initPushData();
  }, []);

  // 푸시 메시지 수신
  useEffect(() => {
    window.fspmobBadgeCount((count, fspmob = true) => {
      // console.log(`### count: ${count}, ${fspmob}`);
      setBadgeCount(count);
      if (fspmob === true) {
        appDeviceBadge(count);
      }
    });
    window.fspmobSelectPushMsg((msgId) => {
      handleDetail(msgId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBadgeCount, handleDetail]);

  return (
    <>
      <IconButtonAnimate
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 24, height: 24, padding: 0 }}
      >
        <Badge badgeContent={badgeCount} color="error">
          <Iconify icon="mdi:bell-outline" sx={{ width: 24, height: 24 }} color="text.primary" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 0,
          ml: 0.75,
          '& > span': {
            display: 'none'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <SelectResultTitle title="미확인 메시지" count={badgeCount} pb={0} />
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 600 } }}>
          <List disablePadding>
            {dsUnReadList.map((notification) => (
              <NotificationItem
                key={notification.MSG_ID}
                notification={notification}
                onSelect={handleDetail}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={RouterLink}
            to="/menu/MM06/MM0602"
            onClick={() => setOpen(false)}
          >
            전체보기
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({
  notification,
  onSelect
}: {
  notification: DS_PUSH;
  onSelect: (msgId: string) => void;
}) {
  const { title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.RECV_DTTM && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={(e) => onSelect(notification.MSG_ID)}
    >
      {/*
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="mdi:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {dataUtils.fDateTime(notification.SEND_DTTM?.substring(0, 12))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------
function renderContent(notification: DS_PUSH) {
  const title = (
    <Typography variant="body2">
      {notification.REG_USER_NM}
      <Typography component="span" variant="body1B">
        &nbsp; {notification.TIT}
      </Typography>
    </Typography>
  );
  return { title };
}
