import { Theme, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  const isLight = theme.palette.mode === 'light';
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: '500',
          minWidth: '0'
        },
        sizeSmall: {
          height: 30,
          fontSize: '14px'
        },
        sizeMedium: {
          height: 44,
          fontSize: '15px'
        },
        sizeLarge: {
          height: 54,
          fontSize: '18px'
        },
        // contained
        containedInherit: {
          color: theme.palette.text.secondary,
          backgroundColor: isLight ? theme.palette.background.border : theme.palette.grey[700]
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.dark
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark
        },
        textInherit: {
          color: theme.palette.text.grey
        }
      }
    }
  };
}
