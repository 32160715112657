import { createSlice } from '@reduxjs/toolkit';
import { store } from 'redux/store';
import { UseHttpApiCallbackType } from '_szcom/utils/fsp';

export type HttpStatusState = {
  code: number;
  loading: boolean;
  message: string;
  success: boolean;
  serviceId: string;
  status: number;
};

const initialState: HttpStatusState = {
  code: 0,
  status: 0,
  message: '',
  loading: false,
  success: true,
  serviceId: ''
};

const slice = createSlice({
  name: 'HttpStatus',
  initialState,
  reducers: {
    start(state, action) {
      state.loading = true;
      state.message = '';
      state.code = 0;
      state.status = 0;
      state.success = true;
      state.serviceId = action.payload.serviceId;
    },
    end(state, action) {
      state.loading = false;
      state.serviceId = action.payload.serviceId;
      state.code = action.payload.code;
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.success = action.payload.success;
    },
    clear(state) {
      state.message = '';
      state.code = 0;
      state.status = 0;
      state.success = true;
      state.serviceId = '';
      state.loading = false;
    }
  }
});

export function clear() {
  const { dispatch } = store;
  dispatch(slice.actions.clear());
}

export function start(serviceId: string) {
  const { dispatch } = store;
  dispatch(slice.actions.start(serviceId));
}

export function end(result: UseHttpApiCallbackType) {
  const { dispatch } = store;
  dispatch(
    slice.actions.end({
      serviceId: result.serviceId,
      message: result.message || result.error?.message,
      status: result.status,
      code: result.code,
      success: result.code === 0
    })
  );
}

// Reducer
export default slice.reducer;
