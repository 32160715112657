import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { OutlinedInput, Stack, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'redux/store';
import { CST0003Props } from './CST0003';
import { sendKey } from './CST0003_A01';
import { toInteger } from 'lodash';
// ----------------------------------------------------------------------
type FormValuesProps = {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;
};
type ValueNames = 'code1' | 'code2' | 'code3' | 'code4' | 'code5' | 'code6';
//------------------------------------------------------------
// 2차 인증 번호 입력 화면
//------------------------------------------------------------

export default function CST0003_L01({ dsPrvc, loginFormData, onClose }: CST0003Props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required(),
    code2: Yup.string().required(),
    code3: Yup.string().required(),
    code4: Yup.string().required(),
    code5: Yup.string().required(),
    code6: Yup.string().required(),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });
  const values = watch();
  useEffect(() => {
    document.addEventListener('paste', handlePasteClipboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 2022.02.15 인증번호 6번째자리 입력 시 blur처리
  useEffect(() => {
    if (values.code6 !== '') {
      (document.activeElement as HTMLElement).blur();
    }
  }, [values])

  const onSubmit = async (data: FormValuesProps) => {
    // 데이터 전송
    const authCode = `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`;
    const { success, code, message } = await dispatch(sendKey(loginFormData, authCode));
    if (success) {
      onClose && onClose(true)
    } else {
      switch(toInteger(code)) {
        case -215: //  "인증확인을 위한 데이터가 존재하지 않습니다."
          alert('인증번호 발급 정보가 없습니다. 재발급 해주십시오.');
          onClose && onClose(false);
        break;
        case -216: // 유효시간 경과 
          alert('인증번호가 만료되었습니다. 재발급하여 입력해주세오.');
          onClose && onClose(false);
          break;
        case -217: //  번호가 올바르지 않다. 다시 입력 
          setErrorMsg('인증번호가 일치하지 않습니다.');
          reset();
        break;
        default:
          setErrorMsg(`[${code}] : ${message}`);
          reset();
          break;
      }
    }
  };

  const handlePasteClipboard = (event: ClipboardEvent) => {
    let data: string | string[] = event?.clipboardData?.getData('Text') || '';

    data = data.split('');

    [].forEach.call(document.querySelectorAll('#field-code'), (node: any, index) => {
      node.value = data[index];
      const fieldIndex = `code${index + 1}`;
      setValue(fieldIndex as ValueNames, data[index]);
    });
  };

  const handleChangeWithNextField = (
    event: React.ChangeEvent<HTMLInputElement>,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  ) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

        if (nextfield !== null) {
          (nextfield as HTMLElement).focus();
        }
      }
    }
    handleChange(event);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <Typography textAlign="center" variant="subtitle1">
        전송된 본인확인 인증번호 6자리를 입력하세요.
      </Typography>
      {!!errorMsg && <Alert severity="error" sx={{ width: '100%', marginTop: 2 }}>{errorMsg}</Alert>}
      <Stack direction="row" spacing={1.5} justifyContent="center" mt={2}>
        {Object.keys(values).map((name, index) => (
          <Controller
            key={name}
            name={`code${index + 1}` as ValueNames}
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                id="field-code"
                type="number"
                autoFocus={index === 0}
                placeholder="-"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeWithNextField(event, field.onChange)
                }
                inputProps={{
                  maxLength: 1,
                  sx: {
                    p: 0,
                    textAlign: 'center',
                    width: { xs: 42, sm: 76 },
                    height: { xs: 42, sm: 76 },
                  },
                }}
              />
            )}
          />
        ))}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        disabled={!isValid}
        sx={{ mt: 3 }}
      >
        인증 번호 확인
      </LoadingButton>
    </form>
  );
}
